import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Styles } from "../../../design/styles";
import {addFieldErrors, removeFieldErrors, selectFieldErrors} from "../../errorStore/errorStoreSlice";
import { ERROR_TYPE } from "../../registrationForm/const";
import { setFieldInformation } from "../../registrationForm/informationPanel/informationPanelSlice";

const InputField = ({ label, info, section, fieldType, onValueChange, initialValue, required, pattern, disable }) => {
    const dispatch = useDispatch()
    const [error, setError] = useState(false)
    const [color, setColor] = useState("primary")
    const errors = useSelector(selectFieldErrors)
    useEffect(() => {
        if (section && fieldType) {
            if (error) dispatch(addFieldErrors(ERROR_TYPE[section][fieldType]))
            else dispatch(removeFieldErrors(ERROR_TYPE[section][fieldType]))
        }
    }, [error,dispatch,fieldType,section])
    return <TextField
        style={Styles.InputField.TextField}
        color={color}
        disabled={disable}
        InputLabelProps={error ? { style: { color: "red" } } : {}}
        type="text"
        label={label}
        variant="outlined"
        required={required}
        onSelect={() => { error ? setColor("error") : setColor("primary"); dispatch(setFieldInformation(info)); }}
        onChange={(e) => { 
            if(e.target.value === '') setError(false)
            onValueChange(fieldType, e.target.value)
             }}
        onBlur={(e) => { if (e.target.value !== "" && pattern !== undefined) { if (pattern.test(e.target.value)) { setError(false); setColor("primary") } else { setError(true); setColor("primary") } } }}
        defaultValue={initialValue}
    />
}


export default InputField;