import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {ThemeProvider} from '@mui/material/styles';
import {useSelector} from 'react-redux';
import {doLogin} from '../functions';
import {selectLoginError} from '../validatorSectionSlice';
import {Alert, AlertTitle} from '@mui/material';
import {store} from '../../../store/store';
import {navigateTo} from '../../navigation/navigationSlice';
import {NavigationPath} from '../../navigation/navigationPath';
import {Styles} from "../../../design/styles";
import {Strings} from "../../../assets/Strings";
import theme from "../../../design/theme";
import {createEmptyAccount, setAccount, setLoginStatus} from "../authSlice";
import {LoginStatus} from "../loginStatus";
import {useEffect} from "react";


export default function LoginSection() {
    const loginError = useSelector(selectLoginError)
    const loginStatus = useSelector(setLoginStatus)
    useEffect(()=>{
        if (loginStatus!== LoginStatus.FAILED){
            store.dispatch(setLoginStatus(LoginStatus.FAILED));
        }
    },[loginStatus])
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        doLogin(data)
    };
    const handleNeedHelp = () => {
        store.dispatch(navigateTo(NavigationPath.SUPPORT))
    };


    return _renderUI(handleSubmit, handleNeedHelp, loginError);
}

// UI ELEMENT
function _renderUI(handleSubmit, handleNeedHelp, loginError) {
    return (<ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs" sx={{marginBottom: "2rem"}}>
            <CssBaseline/>
            <Box sx={Styles.LoginSection.Box.Root}>
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    {Strings.it.loginButtonLabel}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <TextField {...Styles.LoginSection.EmailProps} />
                    <TextField {...Styles.LoginSection.PasswordProps} />

                    <Button {...Styles.LoginSection.SubmitProps}
                            sx={{mt: 3, mb: 2,bgcolor:"secondary.darker"}}
                    >
                        {Strings.it.loginButtonLabel}
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Button variant="body2" sx={{color: "inherited", textDecoration: "none"}}
                                    onClick={handleNeedHelp}>
                                {Strings.it.doYouNeedHelp}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box hidden={loginError.title === ""}>
                <Alert severity="error">
                    <AlertTitle>{loginError.title}</AlertTitle>
                    {loginError.details}
                </Alert>
            </Box>
        </Container>
    </ThemeProvider>);
}