import { createSlice } from "@reduxjs/toolkit";
import {API} from "../../../networkEngine/api";
import {createEmptyFilterParams} from "../functions";
const validatorSearchResultSlice = createSlice({
    name: "validatorSearchResult",

    initialState: {
        validatorUrl: "",
        validatorData:[],
        validatorNextPage: API.validatorSearch,
        validatorPreviousPage: "",
        validatorTotalPage: "",
        validatorCurrentPage: 1,
        needUpdate:false,
        searchParams:createEmptyFilterParams()
    },
    reducers: {
        getValidatorData: (state, action) => {
            state.validatorUrl = action.payload;
        },
        setValidatorData: (state, action) => {
            state.validatorData = action.payload;
        },
        setValidatorNextPage: (state, action) => {
            let pathname=null
            if (action.payload !== null) {
                pathname = ((action.payload).split("api/"))[1]
            }
            state.validatorNextPage = pathname;
        },
        setValidatorPreviousPage: (state, action) => {
            let pathname=null
            if (action.payload !== null) {
                pathname = ((action.payload).split("api/"))[1]
            }
            state.validatorPreviousPage = pathname;
        },
        setValidatorTotalPage: (state, action) => {
            state.validatorTotalPage = action.payload;
        },
        setValidatorCurrentPage: (state, action) => {
            state.validatorCurrentPage = action.payload
        },
        setValidatorNeedUpdate: (state,action) =>{
            state.needUpdate = action.payload
        },
        setSearchParams: (state,action) => {
            state.searchParams = action.payload
        }
    },
});

export const selectSearchResultValidatorData = (state) => state.validatorSearchResult.validatorData;
export const selectSearchValidatorPreviousPageUrl = (state) => state.validatorSearchResult.validatorPreviousPage;
export const selectSearchValidatorCurrentPage = (state) => state.validatorSearchResult.validatorCurrentPage;
export const selectSearchValidatorTotalPage = (state) => state.validatorSearchResult.validatorTotalPage;
export const selectSearchValidatorNextPageUrl = (state) => state.validatorSearchResult.validatorNextPage;
export const selectValidatorNeedUpdate = (state) => state.validatorSearchResult.needUpdate;
export const selectSearchParams = (state) => state.validatorSearchResult.searchParams;


export const {
    setValidatorCurrentPage,
    setValidatorTotalPage,
    setValidatorPreviousPage,
    setValidatorNextPage,
    setValidatorData,
    getValidatorData,
    setValidatorNeedUpdate,
    setSearchParams
} = validatorSearchResultSlice.actions;

export default validatorSearchResultSlice.reducer;
