import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material"
import {useSelector} from "react-redux"
import {store} from "../../store/store"
import {ApiConnectionStatus} from "../registrationForm/const"
import {
    selectApiConnectorIndicator,
    selectApiConnectorIndicatorDetails,
    setApiConnectionStatus,
    setApiConnectionStatusDetails
} from "./apiConnectionIndicatorSlice"
import {Strings} from "../../assets/Strings";

const ApiConnectionIndicator = () => {
    const apiStatus = useSelector(selectApiConnectorIndicator)
    const details = useSelector(selectApiConnectorIndicatorDetails)
    const handleClose = () => {
        store.dispatch(setApiConnectionStatus(ApiConnectionStatus.IDLE))
        store.dispatch(setApiConnectionStatusDetails(Strings.it.loading))
    }
    return _renderUI(apiStatus, handleClose, details);
}


// UI ELEMENT
function _renderUI(apiStatus, handleClose, details) {
    return (
        <div>
            <Dialog
                open={(apiStatus < ApiConnectionStatus.IDLE)}
                onClose={handleClose}
            >
                <DialogTitle>{Strings.it.loading}</DialogTitle>
                <DialogContent>
                    <DialogContentText tabIndex={-1}>
                        {details}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button hidden={(apiStatus === ApiConnectionStatus.LOADING)} onClick={handleClose}>{Strings.it.close}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ApiConnectionIndicator;