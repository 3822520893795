import {store} from "../../store/store";
import {NavigationPath} from "../navigation/navigationPath";
import {navigateTo} from "../navigation/navigationSlice";

export const useNavigateBackInterceptor = (callback) => {
    window.addEventListener('popstate', function (event) {
        window.history.pushState(null, '', window.location.pathname);
        if (window.location.pathname.includes("/census/profiles/not_found")) {
            store.dispatch(navigateTo(NavigationPath.ADVANCED_SEARCH))
        } else if (window.location.pathname.includes("/census/profiles/:")) {
            store.dispatch(navigateTo(NavigationPath.ADVANCED_SEARCH))
        } else if (window.location.pathname.includes("/validator/panel/profiles/:")) {
            store.dispatch(navigateTo(NavigationPath.VALIDATOR_PANEL))
        } else if (window.location.pathname === "/validator/panel") {
            store.dispatch(navigateTo(NavigationPath.LANDING_PAGE))
        } else if (window.location.pathname.includes("validator/panel/profiles/edit/:")) {
            store.dispatch(navigateTo(NavigationPath.VALIDATOR_PANEL))
        } else {
            callback();
        }
        event.stopImmediatePropagation();
    }, false);
}
