import { createSlice } from "@reduxjs/toolkit";

const suggestedKeywordsSlice = createSlice({
    name: 'suggestedKeywords',
    initialState: {
        words: [],
        filteredWords:[],
        searchQuery: ""
    },
    reducers: {
        fetchSuggestedKeyword: (state, action) => {
            state.shouldFetchKeywords = action.payload
        },
        getSuggestedKeywords: (state, action) => {
            state.words = action.payload
        },
        updateSearchQuery: (state, action) => {
            state.searchQuery = action.payload
        },
        setFilteredWords: (state,action) => {
            state.filteredWords = action.payload
        }
    }
})

export default suggestedKeywordsSlice.reducer

export const selectSuggestedKeywords = (state) =>  state.suggestedKeywords.words
export const selectFilteredSuggestedKeywords = (state) =>  state.suggestedKeywords.filteredWords



export const {
    getSuggestedKeywords,
    fetchSuggestedKeyword,
    updateSearchQuery,
    setFilteredWords
} = suggestedKeywordsSlice.actions