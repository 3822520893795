import React from 'react';
import {Box, Button, Typography} from '@mui/material';
import { purple } from '@mui/material/colors';
import { store } from '../../store/store';
import { navigateTo } from '../navigation/navigationSlice';
import { NavigationPath } from '../navigation/navigationPath';
import {Strings} from "../../assets/Strings";
const primary = purple[800];

export default function NotFound() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                backgroundColor: primary,
                minHeight:"50vh",
            }}
        >
            <Typography variant="h1" style={{ color: 'white' }}>
                404
            </Typography>
            <Typography variant="h6" style={{ color: 'white' }}>
                {Strings.it.notFound}
            </Typography>
            <br/>
            <br/>
            <Button onClick={() => { store.dispatch(navigateTo(NavigationPath.LANDING_PAGE)) }} variant="contained">{Strings.it.goHomePage}</Button>
        </Box>
    );
}