export function useQueriesHooks(useMediaQueryHook) {
    return {
        isDesktopOrLaptop: useMediaQueryHook({ query: '(min-width: 1224px)' }),
        isMid: useMediaQueryHook({ query: '(min-width: 900px)' }),
        isBigScreen: useMediaQueryHook({ query: '(min-width: 1824px)' }),
        isTabletOrMobile: useMediaQueryHook({ query: '(max-width: 1224px)' }),
        isPortrait: useMediaQueryHook({ query: '(orientation: portrait)' }),
        isRetina: useMediaQueryHook({ query: '(min-resolution: 2dppx)' }),
        tooSmall : useMediaQueryHook({ query: '(max-width: 300px)' })
    }
}