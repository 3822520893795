import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";

export default function ContentSheet({ header, content }) {
    return (
        <Grid
          container
          rowSpacing={0}
          columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          padding={3}
          justifyContent="center"
          style={{
            marginTop: "65px",

            maxHeight: "70vh",
            overflow: "auto",
            maxWidth: "100vw",
            minHeight: "50vh",
          }}
        >
            <Card sx={{ width: "60%",backgroundColor:"RGB(255,255,255,.2)" }} elevation={1}>
                <CardHeader title={header} />
                <CardContent><Typography>{content}</Typography></CardContent>
            </Card>
        </Grid>
    );
}