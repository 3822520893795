import {Typography} from "@mui/material";
import {Styles} from "../../design/styles";
import {Strings} from "../../assets/Strings";
import {store} from "../../store/store";
import React from "react";
import {capitalizeFirstLetter} from "../../util/functions";
import {useSelector} from "react-redux";
import {selectAccount} from "../validator/authSlice";

const TitleBar = () => {
    const user = useSelector(selectAccount)
    return (
        <>
            <div style={Styles.TitleBar.Root}>
                <div style={Styles.TitleBar.Root}>
                    <Typography
                        {...Styles.TitleBar.MainProps}
                        sx={Styles.TitleBar.MainLine}
                    >
                        {Strings.it.appNameFirst}
                    </Typography>
                    <Typography
                        {...Styles.TitleBar.SubProps}
                        sx={Styles.TitleBar.SubLine}
                    >
                        {Strings.it.appNameSecond}
                    </Typography>
                </div>

            </div>
            <div>
                <div style={{marginRight:"8px",left:0,backgroundColor:"RGB(165,124,123,.1)",padding:"4px",borderRadius:"4px"}}>
                    <Typography variant={"button"} sx={{userSelect:'none'}}>{capitalizeFirstLetter(user.username)}</Typography>
                    <Typography variant={"caption"} component={"h6"} sx={{userSelect:'none'}}>{user.email}</Typography>
                </div>
            </div>
        </>

    );
}
export default TitleBar