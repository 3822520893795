import {
    setApiConnectionStatus,
    setApiConnectionStatusDetails
} from "../apiConnectionIndicator/apiConnectionIndicatorSlice";
import {ApiConnectionStatus} from "../registrationForm/const";
import {verifyToken} from "../validator/functions";
import {navigateTo} from "../navigation/navigationSlice";
import {NavigationPath} from "../navigation/navigationPath";
import {setLoginStatus} from "../validator/authSlice";
import {LoginStatus} from "../validator/loginStatus";
import {Strings} from "../../assets/Strings";
import {NETWORK} from "../../networkEngine/NETWORK";
import {setAllActivities} from "../registrationForm/businessDataSection/businessDataSectionSlice";
import {createEmptyFilterParams, searchValidatorProfiles} from "../searchResult/functions";
import {setValidatorNeedUpdate} from "../searchResult/validator/validatorSearchResultSlice";

export function _handleValidatorPanelPath(dispatch, token) {
    dispatch(setApiConnectionStatus(ApiConnectionStatus.LOADING));
    verifyToken(token,
        (res) => {
            dispatch(setApiConnectionStatusDetails(Strings.it.loading));
            dispatch(navigateTo(NavigationPath.VALIDATOR_PANEL));
            dispatch(setApiConnectionStatus(ApiConnectionStatus.LOGIN));
            dispatch(setLoginStatus(LoginStatus.LOGGED_IN));
        },
        (res) => {
            dispatch(setApiConnectionStatusDetails(Strings.it.pleaseLoginAgain));
            dispatch(setApiConnectionStatus(ApiConnectionStatus.LOADING));
            dispatch(navigateTo(NavigationPath.VALIDATOR_LOGIN));
        });
}

export function _handleValidatorPanelInitialSetup(state, dispatch) {
    const acts = state.activities.allActivities
    if (acts.length === 0) {
        dispatch(setApiConnectionStatus(ApiConnectionStatus.LOADING))
        NETWORK
            .getAllActivitiesWithoutPagination()
            .then((response) => {
                dispatch(setAllActivities(response.data))
                dispatch(setApiConnectionStatus(ApiConnectionStatus.SUCCEED))
                dispatch(setApiConnectionStatus(ApiConnectionStatus.IDLE))
            }).catch((e) => {
            dispatch(setApiConnectionStatusDetails(Strings.it.connectionProblem))
            dispatch(setApiConnectionStatus(ApiConnectionStatus.FAILED))
        })
    }
    searchValidatorProfiles(state.auth.token, createEmptyFilterParams());
    dispatch(setValidatorNeedUpdate(false))
}