import { createSlice } from "@reduxjs/toolkit";
import {createEmptyFilterParams} from "../functions";
const publicSearchResultSlice = createSlice({
    name: "publicSearchResult",

    initialState: {
        publicUrl: "",
        publicData: [],
        publicNextPage: "",
        publicTotalPage: "",
        publicPreviousPage: "",
        publicCurrentPage: 1,
        publicCurrentItem:{},
        searchParams:createEmptyFilterParams()

    },
    reducers: {
        getPublicData: (state, action) => {
            state.publicUrl = action.payload;
        },
        setPublicData: (state, action) => {
            state.publicData = action.payload;
        },
        setPublicNextPage: (state, action) => {
            let pathname=null
            if (action.payload !== null) {
                pathname = ((action.payload).split("api/"))[1]
            }
            state.publicNextPage = pathname;
        },
        setPublicPreviousPage: (state, action) => {
            let pathname=null
            if (action.payload !== null) {
                pathname = ((action.payload).split("api/"))[1]
            }
            state.publicPreviousPage = pathname;
        },
        setPublicTotalPage: (state, action) => {
            state.publicTotalPage = action.payload;
        },
        setPublicCurrentPage: (state, action) => {
            state.publicCurrentPage = action.payload
        },
        setPublicCurrentItem:(state,action) => {
            state.publicCurrentItem = action.payload
        },
        setPublicSearchParams:(state,action) => {
            state.searchParams = action.payload
        }
    },
});

export const selectSearchResultPublicData = (state) => state.publicSearchResult.publicData;
export const selectSearchPublicPreviousPageUrl = (state) => state.publicSearchResult.publicPreviousPage;
export const selectSearchPublicCurrentPage = (state) => state.publicSearchResult.publicCurrentPage;
export const selectSearchPublicTotalPage = (state) => state.publicSearchResult.publicTotalPage;
export const selectSearchPublicNextPageUrl = (state) => state.publicSearchResult.publicNextPage;
export const selectPublicCurrentItem = (state) => state.publicSearchResult.publicCurrentItem;
export const selectPublicSearchParams = (state) => state.publicSearchResult.searchParams;

export const {
    setPublicData,
    getPublicData,
    setPublicNextPage,
    setPublicPreviousPage,
    setPublicTotalPage,
    setPublicCurrentPage,
    setPublicCurrentItem,
    setPublicSearchParams
} = publicSearchResultSlice.actions;

export default publicSearchResultSlice.reducer;
