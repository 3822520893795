import { createSlice } from "@reduxjs/toolkit";
import {tab} from "@testing-library/user-event/dist/tab";

const suggestedKeywordsSlice = createSlice({
    name: 'scheduleSection',
    initialState: {
        tabs:["Sede n.1","Nuova..",],
        activeTab:0,
    },
    reducers: {
        addNewTab: (state,action) => {
            let tabs = [...state.tabs]
            tabs.splice(tabs.length-1, 0, action.payload);
            state.tabs = tabs
        },
        removeTabByPosition:(state,action) =>{
            state.tabs = state.tabs.splice(action.payload,1)
        },

        setActiveTab: (state, action) => {
            state.activeTab = action.payload
        },
        resetTabs: (state) => {
            state.tabs = ["Sede n.1","Nuova.."]
        }
    }
})

export default suggestedKeywordsSlice.reducer

export const selectActiveTab = (state) => state.scheduleSection.activeTab;
export const selectTabs = (state) => state.scheduleSection.tabs;


export const {
    addNewTab,
    setActiveTab,
    resetTabs,
    removeTabByPosition
} = suggestedKeywordsSlice.actions