import {Grid, Tab, Tabs} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {addNewTab, selectActiveTab, selectTabs, setActiveTab} from "./scheduleSectionSlice";
import {a11yProps} from "./functions";
import BranchInformation from "../branchInformation/BranchInformation.js";
import {useQueriesHooks} from "../../global/mediaQueries";
import {useMediaQuery} from "react-responsive";


const ScheduleSection = () => {
    const activeTab = useSelector(selectActiveTab)
    const branches = useSelector(selectTabs)
    const dispatch = useDispatch()
    const handleChange = (event, newValue) => {
        if (newValue === (branches.length - 1)) {
            dispatch(addNewTab(`sede n.${branches.length}`))
            dispatch(setActiveTab(branches.length - 1))
        } else {
            dispatch(setActiveTab(newValue))
        }

    };
    const {isPortrait} = useQueriesHooks(useMediaQuery)
    return (
        <Grid item justifyItems="center" alignContent={"center"}>
            <Grid container direction={!isPortrait ? "column" : "row"}>
                <Grid item xs={3} md={12}>
                    <div
                        orientation={"horizontal"}
                        variant="scrollable"
                        value={activeTab}
                        onChange={handleChange}
                        aria-label="Sede"
                        sx={{
                            borderRight: 1,
                            borderColor: 'divider',
                            maxHeight: 120,
                            minWidth: isPortrait ? "90vw" : "60vw",
                            maxWidth: "80vw"
                        }}
                    >
                        {//branches.map((item, index) => {
                            //return <Tab label={item} {...a11yProps(index)} key={index}/>})
                        }
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
                {branches.map((item, index) => {
                    return <BranchInformation item={item} index={index} key={index + "_" + item.name}
                                              value={activeTab}/>
                })}
            </Grid>
        </Grid>
    );
};

export default ScheduleSection;