import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useDispatch, useSelector} from 'react-redux';
import {close, selectInfoDialog, toggleActive} from './infoDialogSlice';
import {navigateTo} from '../navigation/navigationSlice';
import {NavigationPath} from '../navigation/navigationPath';
import {Styles} from "../../design/styles";
import {Strings} from "../../assets/Strings";

const ScrollDialog = () => {
    const [scroll] = React.useState('paper');
    const dispatch = useDispatch()
    const shouldBeOpen = useSelector(selectInfoDialog)
    const handleClose = () => {
        dispatch(toggleActive());
    };

    const handleRegisterActivity = () => {
        dispatch(close());
        dispatch(navigateTo(NavigationPath.FORM_REGISTRATION))
    }
    const descriptionElementRef = React.useRef(null);


    return (
        <div>
            <Dialog
                open={shouldBeOpen}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                {//<DialogTitle id="scroll-dialog-title">Info</DialogTitle>
                }
                <DialogActions><Button onClick={handleClose}>chiudi</Button></DialogActions>
                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        {Strings.it.howToFillTheForm}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button

                        onClick={handleRegisterActivity}
                        sx={Styles.PopupDialog.RegisterButton}
                    >
                        Registra i tuoi dati
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ScrollDialog