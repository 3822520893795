import { createSlice } from "@reduxjs/toolkit";

const alertSlice = createSlice({
    name: 'alert',
    initialState:{alertContext: {type:"info",message:"Loading..."}},
    reducers: {
        setAlertContext: (state,action) => {
            state.alertContext = action.payload
        }
      }
})

export const selectAlertContext = (state) => state.alert.alertContext;

export const { setAlertContext } = alertSlice.actions
export default alertSlice.reducer