import {NavigationPath} from './navigationPath';
import {NETWORK} from "../../networkEngine/NETWORK";
import {cleanForm, setCurrentSection, setDoConsent,} from '../registrationForm/registrationSlice';
import {ApiConnectionStatus, registrationSectionEnum} from "../registrationForm/const";
import {ACTION_NAVIGATION_TO} from "./ActionType";
import {setAllActivities} from "../registrationForm/businessDataSection/businessDataSectionSlice";
import {setApiConnectionStatus, setApiConnectionStatusDetails} from "../apiConnectionIndicator/apiConnectionIndicatorSlice";
import {resetAllErrors, setHideErrorCamp} from "../errorStore/errorStoreSlice";
import {Strings} from "../../assets/Strings";
import {createEmptyFilterParams, searchPublicProfiles} from "../searchResult/functions";
import {resetTabs, setActiveTab} from "../registrationForm/schedulerTab/scheduleSectionSlice";
import {setActiveBranchIndex, setBranches} from "../registrationForm/branchInformation/branchInformationSlice";
import {setCurrentEntryToExpand, setIsOnModificationSection} from "../validator/validatorSectionSlice";
import {setAllKeywords} from "../commonComponents/richTextField/richTextFieldSlice";
import {createNewBranch} from "../registrationForm/branchInformation/functions";
import {handleProfileEditRequest} from "../registrationForm/functions";
import { setPublicData } from '../searchResult/public/publicSearchResultSlice';


const navigationMiddleware = (store) => (next) => (action) => {
    //Catch the states
    const state = store.getState()
    const dispatch = store.dispatch;
    const at = action?.type || -1;

    if (at === ACTION_NAVIGATION_TO) {
        if (![NavigationPath.EDIT_PROFILE,NavigationPath.FORM_REGISTRATION].includes(action.payload)) {
            store.dispatch(resetAllErrors())
        }
        switch (action.payload) {
            case NavigationPath.EDIT_PROFILE:
                dispatch(resetTabs())
                dispatch(setActiveTab(0))
                dispatch(setActiveBranchIndex(0))
                dispatch(setIsOnModificationSection(true))
                dispatch(setDoConsent("SI"))
                if (state.validatorSection.currentEntryToExpand)
                    handleProfileEditRequest(state.validatorSection.currentEntryToExpand)
                break;
            case NavigationPath.FORM_REGISTRATION:
                dispatch(setCurrentEntryToExpand(null))
                dispatch(setDoConsent("NO"))
                dispatch(setHideErrorCamp(false))
                dispatch(setCurrentSection(registrationSectionEnum.INITIAL_INFORMATION_SECTION))
                dispatch(setIsOnModificationSection(false))
                dispatch(cleanForm())
                dispatch(setBranches([createNewBranch()]))
                dispatch(resetTabs())
                dispatch(setActiveTab(0))
                dispatch(setActiveBranchIndex(0))
                dispatch(setAllKeywords([]))
                break;
            case NavigationPath.ADVANCED_SEARCH:
                _handleAdvanceSearchPath(state, dispatch)
                break;
            default:
                break;
        }
    }
    next(action)
}

export default navigationMiddleware;

function _handleAdvanceSearchPath(state, dispatch) {
    const acts = state.activities.allActivities
    if (acts.length === 0) {
        dispatch(setApiConnectionStatus(ApiConnectionStatus.LOADING))
        NETWORK
            .getAllActivitiesWithoutPagination()
            .then((response) => {
                dispatch(setAllActivities(response.data))
                dispatch(setApiConnectionStatus(ApiConnectionStatus.SUCCEED))
                dispatch(setApiConnectionStatus(ApiConnectionStatus.IDLE))
            }).catch((e) => {
            dispatch(setApiConnectionStatusDetails(Strings.it.connectionProblem))
            dispatch(setApiConnectionStatus(ApiConnectionStatus.FAILED))
        })
    }
    dispatch(setPublicData([]));
    searchPublicProfiles(createEmptyFilterParams());
}

