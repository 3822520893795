import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { store } from "./store/store"
import './index.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { PersistGate } from "redux-persist/integration/react";
import { persistHandler } from './store';
import {BrowserRouter as Router} from 'react-router-dom';
import CssBaseline from "@mui/material/CssBaseline";

createRoot(document.getElementById('root')).render(
    <Router>
        <CssBaseline/>
        <React.StrictMode>
        <Provider store={store}>
          <PersistGate persistor={persistHandler} loading={null}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <App />
            </LocalizationProvider>
          </PersistGate>
        </Provider>
      </React.StrictMode>
    </Router>
);

reportWebVitals();
