import { Drawer } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Styles } from '../../design/styles';
import { menuItems } from '../../util/const';
import { toggle, close, selectDrawer } from './drawerSlice';
import MenuList from './MenuList';

const SideDrawer = () => {
    const dispatch = useDispatch()
    let isOpen = useSelector(selectDrawer)
    const onClose = () => dispatch(close())
    const onToggle = () => dispatch(toggle())

    return _renderUI(isOpen, onClose, onToggle);
}


// UI ELEMENT
function _renderUI(isOpen, onClose, onToggle) {
    return (
        <Drawer
            {...Styles.SideDrawer.Drawer.Props}
            open={isOpen}
            onClose={onClose}
            ModalProps={{onBackdropClick: onClose}}
        >
            <MenuList items={menuItems} onClick={onToggle}/>
        </Drawer>
    );
}


export default SideDrawer