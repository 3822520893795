import { createSlice } from "@reduxjs/toolkit";
const activitiesSlice = createSlice({
    name: "activities",
  
    initialState: {
      allActivities:[],
    },
    reducers: {
      setAllActivities: (state,action) =>{
        state.allActivities = action.payload
      },
      getActivityData: (state,action) => {}
    },
  });
  
  export const selectAllActivities = (state) => state.activities.allActivities;
  export const { setAllActivities, getActivityData} = activitiesSlice.actions;

  export default activitiesSlice.reducer;
  