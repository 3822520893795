import {Grid, Step, StepLabel, Stepper, Typography, Box, Button} from "@mui/material";
import {useQueriesHooks} from "../global/mediaQueries";
import {RegistrationSections} from "./const";
import FormController from "./FormController";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentSection} from "./registrationSlice";
import {useMediaQuery} from 'react-responsive'
import {selectCurrentEntryToExpand, selectIsOnModificationSection} from "../validator/validatorSectionSlice";
import {Strings} from "../../assets/Strings";
import Card from "@mui/material/Card";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {requestValidation} from "../../networkEngine/functions";
import {validationStatus} from "../../util/const";
import {selectFieldErrors} from "../errorStore/errorStoreSlice";

function FormPanel() {
    const profile = useSelector(selectCurrentEntryToExpand)
    const doValidation = () => {
        requestValidation(profile, validationStatus.ACCEPTED)
    }
    const currentSection = useSelector(selectCurrentSection);
    const {isPortrait, isMid} = useQueriesHooks(useMediaQuery);
    const isOnModificationSection = useSelector(selectIsOnModificationSection)
    const errors = useSelector(selectFieldErrors)
    const isThereAnyErrors = errors.length > 0
    const dispatch = useDispatch()
    let vs = profile?profile.validationStatus:validationStatus.TO_VALIDATED
    return renderUI(isOnModificationSection, dispatch, currentSection, isPortrait, isMid,doValidation,vs,isThereAnyErrors);
}

function renderUI(isOnModificationSection, dispatch, currentSection, isPortrait, isMid,doValidate,validationStatus,isError) {
    return (
        <Card
            sx={{
                backgroundColor: "RGB(85,85,25,.1)",
                position: "relative",
                width: isPortrait?"100vw":"70%",
                height: "",
                minHeight: "70vh",
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: "16px",
                borderRadius: "5px",
                overflowY: "auto",
                overflowX: "hidden",
                border: isError? "2px dotted red":""
            }}
            elevation={3}
        >
            {/*Registration Title*/}
            <Grid container direction={"row"} spacing={1}>
                <Grid item>
                    <Typography variant="h5" padding={2}>
                        {Strings.it.formRegistrationTitle}
                    </Typography>
                </Grid>
                <Grid item justifyContent="center">
                    {isOnModificationSection && validationStatus === validationStatus.TO_VALIDATED ?
                        <Button
                            sx={{marginTop: "1rem"}}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                doValidate()
                            }}>
                            {Strings.it.validate}
                        </Button> : <></>}
                </Grid>
            </Grid>

            {/*Registration path*/}
            {<Box sx={{width: "100%", margin: "1rem 0 1rem 0", padding: "8px"}}>
                <Stepper
                    activeStep={currentSection}
                    alternativeLabel={isPortrait} orientation={"horizontal"}
                >
                    {RegistrationSections.map((sec) => (
                        <Step key={sec.name}>
                            <StepLabel>{(isPortrait || !isMid) ?
                                <FontAwesomeIcon icon={sec.icon}/> : sec.label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>}
            {/*ACTUAL CONTENT*/}
            <div style={{position: "relative", backgroundColor: "greenyellow", height: ""}}>
            {/*Registration steps*/}
            </div>
            <Grid container direction={"row"} spacing={3} justifyContent={"center"} justifyItems={"center"} alignContent={"center"}>
                <Grid item xs={12} md={12} sx={{
                    backgroundColor: "",
                    padding: "16px",
                    overflow:"auto"
                }}>{RegistrationSections[currentSection].component}</Grid>
                <Grid item xs={12} md={12} sx={{position: "", bottom: "", backgroundColor: ""}}><FormController/></Grid>
            </Grid>
        </Card>
    );
}


export default FormPanel;

