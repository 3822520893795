import {useDispatch, useSelector} from "react-redux";
import {useQueriesHooks} from "../../global/mediaQueries";
import {useMediaQuery} from "react-responsive";
import Paginator from "../../commonComponents/Paginator";
import {Grid} from "@mui/material";
import Alert from "../../alert/Alert";
import PublicRegisteredProfile from "./PublicRegisteredProfile";
import {
    getPublicData,
    selectSearchPublicCurrentPage,
    selectSearchPublicNextPageUrl,
    selectSearchPublicPreviousPageUrl, selectSearchPublicTotalPage,
    selectSearchResultPublicData, setPublicCurrentPage
} from "./publicSearchResultSlice";

export default function PublicSearchView(){
    const dispatch = useDispatch()
    const data = useSelector(selectSearchResultPublicData)
    const nextPageUrl = useSelector(selectSearchPublicNextPageUrl)
    const previousPageUrl = useSelector(selectSearchPublicPreviousPageUrl)
    const totalPage = useSelector(selectSearchPublicTotalPage)
    const currentPage = useSelector(selectSearchPublicCurrentPage)
    const {isPortrait} = useQueriesHooks(useMediaQuery)
    const handlePageChange = (_, value) => {
        if (value > currentPage) { dispatch(getPublicData(nextPageUrl)) }
        if (value < currentPage) { dispatch(getPublicData(previousPageUrl)) }
        dispatch(setPublicCurrentPage(value))
    };
    return renderUI(data, totalPage, handlePageChange,isPortrait,currentPage);
};

function renderUI(data, totalPage, handlePageChange,isPortrait,currentPage) {
    return (
        <div
            style={{
                marginTop: "0",
                marginLeft:"auto",
                marginRight:"auto",
                marginBottom: "",
                backgroundColor: "",
                width: isPortrait?"":"90vw",
                justifyContent: "center",
            }}
        >
            <Paginator totalPage={totalPage} handlePageChange={handlePageChange} currentPage={currentPage}/>
            <Grid
                container
                rowSpacing={0}
                columnSpacing={{xs: 1, sm: 1, md: 2}}
                padding={1}
                justifyContent="center"
                style={{
                    overflow: "auto",
                    minHeight:"40vh"
                }}
            >
                {data.length === 0 ? <Alert type="info" msg="nessun profilo da visualizzare"/> : data
                    .map((p, index) => {
                        let item = {...p}
                        item.title = p.activityName;
                        item.subTitle = p.referName? p.referName:"-";
                        return <PublicRegisteredProfile key={index} item={item}/>;
                    })}
            </Grid>
        </div>
    );
}
