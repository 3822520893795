import {Box, FormControlLabel, Grid, Typography, Checkbox} from "@mui/material";
import {createEmptyDay, hoursToMomentObject, momentObjectToHours} from "./functions";
import HourPicker from "./HourPicker";
import {Strings} from "../../../assets/Strings";
import {useState} from "react";
import {ArrowDownward, ArrowUpward} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {Styles} from "../../../design/styles";

const Day = ({item, name, onValueChange, initialValue}) => {
    let day = createEmptyDay(name); //00:00
    let initDay = initialValue(name);
    if (initDay !== undefined) {
        day = {...initDay}
    }
    const [morningClose, setMorningClose] = useState(day.morningClose)
    const [eveningClose, setEveningClose] = useState(day.eveningClose)
    const [min, setMin] = useState(true)
    const composeOnValueChange = (fieldType, value) => {
        let HHmm = momentObjectToHours(value)
        if (fieldType === "OPEN_MORNING") {
            day.openingTimeMorning = HHmm;
        }
        if (fieldType === "CLOSE_MORNING") {
            day.closingTimeMorning = HHmm;
        }
        if (fieldType === "OPEN_EVENING") {
            day.openingTimeEvening = HHmm;
        }
        if (fieldType === "CLOSE_EVENING") {
            day.closingTimeEvening = HHmm;
        }
    };
    const extractInitialValue = (name, fieldType) => {
        if (fieldType === "OPEN_MORNING") return hoursToMomentObject(day.openingTimeMorning);
        if (fieldType === "CLOSE_MORNING") return hoursToMomentObject(day.closingTimeMorning);
        if (fieldType === "OPEN_EVENING") return hoursToMomentObject(day.openingTimeEvening);
        if (fieldType === "CLOSE_EVENING") return hoursToMomentObject(day.closingTimeEvening);
    };
    const setMorningDayToClose = (v) => {
        day = {...day, morningClose: v};
        onValueChange(name, day);
        setMorningClose(v)
    };

    const setEveningDayToClose = (v) => {
        day = {...day, eveningClose: v};
        onValueChange(name, day);
        setEveningClose(v)
    };
    return (
        <Box
            sx={{...Styles.Day.Root, height: min ? "50px" : ""}}
        >
            <IconButton sx={{position: "absolute", right: "0"}}
                        onClick={() => {
                            setMin(!min)
                        }}
            >{min ? <ArrowDownward/> : <ArrowUpward/>}</IconButton>
            <Grid container sx={{display: ""}}>
                <Grid item xs={12} md={12}>
                    <Grid container>
                        <Grid item xs={6} md={6}>
                            <Typography>{item}</Typography>
                        </Grid>
                    </Grid>

                    <Grid container sx={{display: min ? "none" : "inherited"}}>
                        <FormControlLabel
                            sx={{
                                position: "static",
                                bottom: "0",
                                right: "0",
                                margin: "8px",
                            }}
                            control={
                                <Checkbox
                                    defaultChecked={day.morningClose}
                                    onChange={(e, v) => {
                                        setMorningDayToClose(v);
                                    }}
                                />
                            }
                            label={Strings.it.closed}
                        />
                        <div style={{opacity: morningClose ? 0.15 : 1, pointerEvents: morningClose ? "none" : 'initial',}}>
                            <HourPicker
                                label={Strings.it.morningOpens}
                                fieldType="OPEN_MORNING"
                                initialValue={extractInitialValue(name, "OPEN_MORNING")}
                                onValueChange={composeOnValueChange}
                            />
                            <HourPicker
                                label={Strings.it.morningCloses}
                                fieldType="CLOSE_MORNING"
                                initialValue={extractInitialValue(name, "CLOSE_MORNING")}
                                onValueChange={composeOnValueChange}
                            />
                        </div>
                        <FormControlLabel
                            sx={{
                                position: "static",
                                bottom: "0",
                                right: "0",
                                margin: "8px",
                            }}
                            control={
                                <Checkbox
                                    defaultChecked={day.eveningClose}
                                    onChange={(e, v) => {
                                        setEveningDayToClose(v);
                                    }}
                                />
                            }
                            label={Strings.it.closed}
                        />
                        <div style={{opacity: eveningClose ? 0.15 : 1, pointerEvents: eveningClose ? "none" : 'initial',}}>

                            <HourPicker
                                label={Strings.it.eveningOpens}
                                fieldType="OPEN_EVENING"
                                initialValue={extractInitialValue(name, "OPEN_EVENING")}
                                onValueChange={composeOnValueChange}
                            />
                            <HourPicker
                                label={Strings.it.eveningCloses}
                                fieldType="CLOSE_EVENING"
                                initialValue={extractInitialValue(name, "CLOSE_EVENING")}
                                onValueChange={composeOnValueChange}
                            />
                            </div>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Day;
