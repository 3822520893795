import {Box, Grid} from "@mui/material"
import {FooterItem} from "./FooterItem"

export const FooterLinkSection = ({section}) => {
    return (
        <Grid item xs={10} sm={4}>
            <Box borderBottom={1}>{section.title}</Box>
            {section.items.map((item, index) => <FooterItem item={item} key={item.navigationPath + "_" + index}/>)}
        </Grid>
    )
}