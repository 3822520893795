import {Grid} from "@mui/material";
import KeywordsContainer from "./KeywordsContainer";
import SuggestedKeywordsContainer from "./SuggestedKeywordsContainer";
import RichTextField from '../../commonComponents/richTextField/RichTextField.js'
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentSection, setCurrentSection} from "../registrationSlice";
import {registrationSectionEnum} from "../const";

const DescriptionSection = ({}) => {
    const cs = useSelector(selectCurrentSection)
    const dispatch = useDispatch()
    if (cs !== registrationSectionEnum.BUSINESS_DESCRIPTION_SECTION) dispatch(setCurrentSection(registrationSectionEnum.BUSINESS_DESCRIPTION_SECTION))
    return (
        <Grid
            container
            justifyItems="center"
            direction="row"
            spacing={3}
            sx={{backgroundColor: ""}}
        >
            <Grid item xs={12} md={6} sx={{overflowY: "hidden", backgroundColor: ""}}>
                <SuggestedKeywordsContainer/>
            </Grid>
            <Grid item xs={12} md={6} sx={{backgroundColor: ""}}>
                <Grid container direction={"row"}>
                    <Grid item xs={12} md={12}>
                        <RichTextField/>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{overflow: "auto", backgroundColor: ""}}>
                        <KeywordsContainer/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DescriptionSection;