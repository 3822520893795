import {Colors} from "./colors";
import theme from "./theme";

export const Styles = {
    Day:{
        Root:{
            borderColor: "RGB(255,255,255,.2)",
            borderStyle: "solid",
            borderRadius: "5px",
            position: "relative",
            overflow: "hidden"
        }
    },
    Layout: {
        Root: {marginLeft:"auto",marginRight:"auto",position:"relative",height:"100vh"}
    },
    General:{
        ErrorSection: {
            width:"250px",height:"",position:"absolute",zIndex:"13",top:"140px",left:"50%",right:"50%",transform: "translate(-50%, -50%)",display:"inline-block"
        },
    },
    Header: {
        Root: {position: "relative", marginTop: "2rem", flexGrow: '1',zIndex:"12"}
    },
    AppBar: {height: "70px", position: "static",zIndex:"12", width: "100vw", marginBottom: "10px", top: "0"},
    LogoType: {LogoFlag: {Image: {maxWidth: "140px"}}},
    TitleBar: {
        MainProps: {variant: "h6", noWrap: true, component: "div"},
        SubProps: {variant: "P0", noWrap: true, component: "div"},
        Root: {flexGrow: 1, display: {xs: 'none', sm: 'block',position:"relative"}},
        MainLine: {flexGrow: 1, display: {xs: 'none', sm: 'block'}},
        SubLine: {flexGrow: 1, display: {xs: 'none', sm: 'block'},}
    },
    FormPanel: {
        SectionHolder: {
            Props: {
                item: true,
                xs: 12,
                md: 12,
            },
        }
    },
    StyledInputBase: {
        inputProps: {'aria-label': 'search'}
    },
    HamburgerMenu: {
        IconButton: {
            mr: 2,
            Props: {
                "size": "large",
                "edge": "end",
                "color": "inherit",
                "aria-label": "open drawer"
            }
        }
    },
    InfoButton: {
        IconButton: {
            mr: 2,
            Props: {
                "size": "large",
                "edge": "end",
                "color": "inherit",
                "aria-label": "open info"
            }
        }
    },
    SideDrawer: {
        Drawer: {
            Props: {
                "anchor": 'right'
            }
        }
    },
    Footer: {
        Button:{top:"-40px",right:"0",position:"",backgroundColor:"white"},
        Box: {
            Props: {
                "px": "{{ xs: 1, sm: 2 }}",
                "py": "{{ xs: 2, sm: 2 }}",
                "bgcolor": theme.palette.primary.main,
                "color": "white",
            },
            Style: {
                width: "80vw",
                minHeight:100,
                marginRight: "auto",
                marginLeft: "auto",
                position:"static",
                bottom:0,
                right:0,
                left:0,
                borderRadius: "5px 5px 0px 0px",
            },
        },
        CopyRightSection: {
            Props: {
                pt: "{{ xs: 1, sm: 1 }}",
                pb: "{{ xs: 2, sm: 0 }}",
                textAlign: "center"
            }
        }
    },
    FooterItem: {
        Button: {
            Props: {
                color: "inherit", variant: "text"
            }
        }
    },
    PopupDialog: {
        RegisterButton: {
            backgroundColor: "#582447",
            color: "white",
            margin: 0
        }
    },
    Scaffold: {
        Root: {
            position:"relative",
            backgroundColor:"",
            scrollableY:"true",
            margin:"0 0rem 0rem 0rem",
            minHeight:"70vh",
            paddingBottom:"2rem"
        },
    },
    Paginator: {
        Props: {
            variant: "outlined",
            color: "secondary",
            position: "static"
        },
        Pagination: {
            display: "flex",
            justifyContent: "center",
            padding: "1rem",
        }
    },
    LandingPage: {
        Root: {
            Props: {
                container: true,
                spacing: 0,
                direction: "row",
                alignItems: "center",
                justifyContent: "center",
            },
            Style: {
                marginLeft: "auto",
                marginRight: "auto",
                width: "80vw",
            }
        }
    },
    LoginSection: {
        Box: {
            Root: {
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            },
        },
        EmailProps: {
            margin: "normal",
            required: true,
            fullWidth: true,
            id: "email",
            label: "Email",
            name: "email",
            autoComplete: "email",
            autoFocus: true,
        },
        PasswordProps: {
            margin: "normal",
            required: true,
            fullWidth: true,
            id: "password",
            label: "Password",
            name: "password",
            type: "password",
            autoComplete: "current-password"
        },
        SubmitProps: {
            type: "submit",
            fullWidth: true,
            variant: "contained"
        },
    },
    FormRegistrationResult: {
        Root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '100vh',
            backgroundColor: Colors.PURPLE,
        }
    },
    InformationPanel: {
        Root: {
            backgroundColor: "RGB(220,220,220,1)",
            display: "block",
            position:"absolute",
            padding:"8px",
            borderRadius:"5px 5px 5px 5px",
            overflow: "-moz-scrollbars-vertical",
            overflowY: "scroll"
        },
    },
    CarouselItem: {
        Props: {alt: "picture", height: "194"},
        Root: {backgroundColor: "red", position: "relative"},
        TitleSection: {
            position: "absolute",
            left: "0",
            margin: "4rem",

            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            borderRadius: "10px",
        },
        CardMedia: {minWidth: "100%", maxHeight: "100%"},
    },
    CarouselTop: {
        Carousel: {
            marginBottom: "10px",
            marginRight: "auto",
            marginLeft: "auto",
            marginTop: "100px",
        }
    },
    InputField: {
        TextField: {width: "200px", margin: "10px", transition: '1s'}
    },
    RichTextField: {
        FabSection: {
            Fab: {
                position: "absolute",
                right: "0",
                bottom: "0",
                margin: "15px",
                fontWeight: "10px"
            }
        }
    },
    HealthcareProviderProfile: {
        Props: {
            container: true,
            spacing: 1,
            justifyContent: "center",
        },
        Root: {
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "86px",
            position: "relative",
            overflow: "auto",
            width: "70vw",
            maxHeight: "75vh",
            padding: "16px",
            elevation: 1,
            backgroundColor: "RGB(231,231,231,.1)"
        },
        Card: {},
        PersonalDataComponent: {
            Props: {
                container: false,
                alignItems: "left",
                justify: "left",
            },
            Root: {
                position: "relative",
                height: "430px",
            },
            Card: {
                position: "relative",
            },
            Header: {
                position: "static",
                top: 0,
                right: 0,
                zIndex: 1,
                textAlign: "left",
            },
            General: {
                position: "absolute",
                top: 64,
                right: 0,
                zIndex: 1,
                "& p": {fontSize: 14},
                "& span": {
                    position: "absolute",
                    bottom: "1rem",
                    right: "1rem"
                }
            }
        },
        ActivityDetailsComponent: {
            List: {},
            Cards: {overflow: "auto", height: "150px", backgroundColor: "RGB(233,233,233,.1)"}
        },
        BranchListComponent: {
            GridContainer: {backgroundColor: ""},
            GridTabList: {overflowY: "auto"},
            GridTabListProp: {item: true, xs: 12, md: 12},
            BoxTabList: {borderBottom: 1, borderColor: 'divider'},
        },
    },
    RegistrationLayout: {
        Root: {
            backgroundColor: "white",
        },
    },
}