import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Box, Grid, Tab, Typography} from "@mui/material";
import * as React from 'react';
import {Styles} from "../../design/styles";
import BranchDetailComponent from "./BranchDetailComponent";


export default function BranchListComponent({branches}) {
    const [value, setValue] = React.useState(0);
    const tabList = [{name: "sede 1", address: "12"}]
    const handleChange = (_, newValue) => {setValue(newValue);};
    return (
        <TabContext value={value}>
            <Grid container justifyContent={"center"} alignItems={"center"}
                  sx={Styles.HealthcareProviderProfile.BranchListComponent.GridContainer}>
                <Grid
                    {...Styles.HealthcareProviderProfile.BranchListComponent.GridTabListProp}
                    sx={Styles.HealthcareProviderProfile.BranchListComponent.GridTabList}>
                    <Box sx={Styles.HealthcareProviderProfile.BranchListComponent.BoxTabList}>
                        <div style={{margin:8}}>
                            {
                                (branches !== undefined && branches.length !== 0)
                                    ? branches.map((item, index) => {
                                        return <Typography key={index}>{item.name ? item.name : item.address?item.address:""}</Typography>
                                    })
                                    : tabList.map((item, index) => {
                                        return <Typography key={index} variant="h6">{item.address?item.address:"Sede"}</Typography>
                                    })
                            }
                        </div>
                    </Box>
                </Grid>
                <Grid item
                      xs={12}
                      md={12}>
                    {
                        (branches !== undefined && branches.length !== 0)
                            ? branches.map((item, index) => {
                                return <TabPanel value={index}><BranchDetailComponent branch={item} key={index}/></TabPanel>
                            })
                            : tabList.map((item, index) => {
                                return <TabPanel value={index}><BranchDetailComponent branch={item} key={index}/></TabPanel>
                            })
                    }
                </Grid>
            </Grid>
        </TabContext>
    )
}

