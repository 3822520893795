import {createSlice} from "@reduxjs/toolkit";

const errorStoreSlice = createSlice({
    name: "errorStore",

    initialState: {
        fieldErrors: [],
        hideErrorCamp: false
    },
    reducers: {
        addFieldErrors: (state, action) => {
            let fieldErrors = [...state.fieldErrors]
            fieldErrors = fieldErrors.filter(item => item.fieldName === action.payload.fieldName)
            if (fieldErrors.length === 0) state.fieldErrors = [...state.fieldErrors, action.payload]
        },
        removeFieldErrors: (state, action) => {
            let fieldErrors = [...state.fieldErrors]
            fieldErrors = fieldErrors.filter(item => item.fieldName !== action.payload.fieldName)
            state.fieldErrors = fieldErrors
        },
        resetAllErrors:(state, action) => {
            state.fieldErrors =[]
        },
        setHideErrorCamp: (state, action) => {
            state.hideErrorCamp = action.payload
        }
    }
});
export default errorStoreSlice.reducer
export const selectFieldErrors = (state) => state.errorStore.fieldErrors;
export const selectHideErrorCamp = (state) => state.errorStore.hideErrorCamp;

export const {
    addFieldErrors,
    removeFieldErrors,
    resetAllErrors,
    setHideErrorCamp
} = errorStoreSlice.actions;