import { createSlice } from "@reduxjs/toolkit";
const richTextFieldSlice = createSlice({
    name: "richTextField",
  
    initialState: {
      keywords: [],
      wordToEvaluate:"",
      enableButton:false
    },
    reducers: {
      addWordToKeywords: (state, action) => {
        var keywords = [...state.keywords]
        keywords = keywords.filter(item => item === action.payload)
        if(keywords.length === 0) state.keywords = [...state.keywords,action.payload]
      },
      removeWordToKeywords: (state, action) => {
        var keywords = [...state.keywords]
        keywords = keywords.filter(item => item !== action.payload)
        state.keywords = keywords
      },
      holdWordForEvaluate: (state, action) => {
        state.wordToEvaluate = action.payload
      },
      showAddButton: (state,action)=> {
        state.enableButton = action.payload
      },
      setAllKeywords: (state,action) =>{
        state.keywords = action.payload
      }
  }});
  
  export const selectWordToKeyword = (state) => state.richTextField.keywords;
  export const selectWordToEvaluate = (state) => state.richTextField.wordToEvaluate;
  export const selectEnableButton = (state) => state.richTextField.enableButton;
  
  export const {
    addWordToKeywords,
    removeWordToKeywords,
    holdWordForEvaluate,
    showAddButton,
    setAllKeywords
  } = richTextFieldSlice.actions;
  
  export default richTextFieldSlice.reducer;
  

  export function highlightTheKeyword(keyword) {
    return <span style={{color: "blue"}}>{keyword}</span>
  }
  export function mergeSpanKeywordIntoText(){}