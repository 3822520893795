export const API = {
    baseUrl: "/api/",
    validatorSearch:"census/validator/search",
    publicSearch:"census/public/search",

    updateRegisteredForm: "census/toValidateForm/",

    activities: "census/data/activities",
    allActivities: "census/data/allActivities",
    keywords: "census/data/keywords",
    registerActivity: "census/register/",
    loginValidator: "auth/login/",
    logoutUser: "auth/logout/",
    NEWS_FIRST_PAGE_URL: "news",
    CHECK_URL: "census/ids",
    VERIFY_TOKEN:"token/verify/",
    PROFILE_BY_URI:"census/urls",
    DELETE_PROFILE:'census/delete'
}