import {createSlice} from "@reduxjs/toolkit";
import {createNewBranch} from "./functions";

const branchInformationSlice = createSlice({
    name: 'branchInformation',
    initialState: {
        branches:[createNewBranch()],
        currentBranchIndex: 0
    },
    reducers: {
        addNewBranch: (state,action) => {
            state.branches = [...state.branches, action.payload]
        },
        setActiveBranchIndex: (state,action) => {
            state.currentBranchIndex = action.payload
        },
        updateBranchInformation: (state,action) => {
            let branches = [...state.branches]
            branches[state.currentBranchIndex] = action.payload
            state.branches = branches
        },
        setBranches:(state,action)=>{
            state.branches = action.payload
        }
    }
})

export default branchInformationSlice.reducer

export const selectBranches = (state) => state.branchInformation.branches;
export const selectActiveBranchIndex = (state) => state.branchInformation.currentBranchIndex;
export const selectActiveBranchData = (state) => 
    state.branchInformation.branches[state.branchInformation.currentBranchIndex];


export const {
    addNewBranch,
    setActiveBranchIndex,
    updateBranchInformation,
    setBranches
} = branchInformationSlice.actions