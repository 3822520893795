import {store} from "../../../store/store";
import {
    selectCurrentSection,
    selectDoReadInitialInformation,
    setCurrentSection,
    setDoReadInitialInformation
} from "../registrationSlice";
import {Button, Checkbox, FormControlLabel, Typography} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {Box} from "@mui/system";
import {Strings} from "../../../assets/Strings";
import {registrationSectionEnum} from "../const";
import { privacyToggleActive } from "./privacyDialogSlice";
import PrivacyPolicyPopup from "./PrivacyPolicyPopup";

function InitialRegistrationInformation() {
    const isChecked = useSelector(selectDoReadInitialInformation)
    const cs = useSelector(selectCurrentSection)
    const dispatch = useDispatch()
    const showPrivacyPolicy = () => {
        dispatch(privacyToggleActive())
    }
    if (cs!==registrationSectionEnum.INITIAL_INFORMATION_SECTION) dispatch(setCurrentSection(registrationSectionEnum.INITIAL_INFORMATION_SECTION))
    return renderUI(isChecked,showPrivacyPolicy)
}

//UI ELEMENT
function renderUI(isChecked,showPrivacyPolicy) {
    return <Box padding={5}>
        <Typography variant={"h5"}>{Strings.it.beforeYouBegin}</Typography>
        <Typography variant={"p"} display="inline">{Strings.it.beforeYouBeginDesc}</Typography>
        <Typography variant={"p"}> dell’</Typography>
        <Button 
        variant="text"
        color="secondary"
        sx={{margin:0,padding:0,capitalized:false,textTransform: 'none',fontFamily:"inherit",fontSize:15}}
        onClick={showPrivacyPolicy}
        >informativa</Button>
        <Typography variant={"p"}> sulla privacy.</Typography>
        <br/>
        <br/>
        <FormControlLabel control={<Checkbox
            checked={isChecked}
            onChange={() => {
                store.dispatch(setDoReadInitialInformation(!isChecked))
            }}
        />} label={Strings.it.accepted}/>
        <PrivacyPolicyPopup/>
    </Box>;
}

export default InitialRegistrationInformation;