import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import SideDrawer from '../SideDrawer/SideDrawer';
import TitleBar from "./TitleBar"
import { HamburgerMenu } from '../SideDrawer/HamburgerMenu';
import { Styles } from '../../design/styles';
import { LogoFlag } from '../commonComponents/Logo';
import { InfoButton } from '../infoDialog/InfoButton';
import { Box } from '@mui/material';


const Header = () => {

  return (
    <Box sx={Styles.AppBar}>
      <AppBar >
        <Toolbar>
          <LogoFlag />
          <TitleBar />
          <HamburgerMenu />
          <InfoButton />
        </Toolbar>
      </AppBar>
      <SideDrawer />
    </Box>
  );
}

export default Header
