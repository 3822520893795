import { createSlice } from "@reduxjs/toolkit";
import { getHealthcareWorkerEmptyObject, getPersonalDataEmptyObject } from "./functions";
import {associationPartnership, validationStatus} from "../../util/const";

const registrationSlice = createSlice({
    name: 'registrationForm',
    initialState: {
        inputPersonalInfo: getPersonalDataEmptyObject(),
        healthcareWorkerData: getHealthcareWorkerEmptyObject(),
        inputBusinessActivity: [],
        inputDescription: "",
        inputSchedule: {},
        selectedInfo: "",
        currentSection:0, 
        remoteAvailableActivities: [],
        remoteAvailableKeywords: [],
        isDataConfirmed: false,
        isLeavingConsent: false,
        shouldSaveData: true,
        samePersonFillForm: true,
        formRegistrationResult: {},
        registrationForModification:false,
        doReadInitialInformation:false,
        doConsent:"NO",
        doParticipate:associationPartnership.SI,
        validationStatus: validationStatus.TO_VALIDATED,
    },
    reducers: {
        setPersonalInformation: (state, action) => {
            state.inputPersonalInfo = action.payload
        },
        setBusinessActivity: (state, action) => {
            state.inputBusinessActivity = action.payload
        },
        addBusinessActivity: (state, action) => {
            let activity = { name: action.payload.name, parentName: action.payload.parentName }
            state.inputBusinessActivity = [activity, ...state.inputBusinessActivity]
        },
        removeBusinessActivity: (state, action) => {
            let activities = [...state.inputBusinessActivity]
            activities = activities.filter(item => item.name !== action.payload.name)
            state.inputBusinessActivity = activities
        },
        setDescription: (state, action) => {
            state.inputDescription = action.payload
        },
        setSchedule: (state, action) => {
            state.inputSchedule = action.payload
        },
        setSelectedInfo: (state, action) => {
            state.selectedInfo = action.payload
        },
        setCurrentSection: (state, action) => {
            state.currentSection = action.payload
        },
        setAvailableActivities: (state, action) => {
            state.remoteAvailableActivities = action.payload
        },
        setAvailableKeywords: (state, action) => {
            state.remoteAvailableKeywords = action.payload
        },
        setIsDataConfirmed: (state, action) => {
            state.isDataConfirmed = action.payload
        },
        setIsLeavingConsent: (state, action) => {
            state.isLeavingConsent = action.payload
        },
        setShouldSaveData: (state, action) => {
            state.shouldSaveData = action.payload
        },
        setHealthcareWorkerData(state, action) {
            state.healthcareWorkerData = action.payload
        },
        setSamePersonFillForm(state, action) {
            state.samePersonFillForm = action.payload
        },
        cleanForm: (state) => {
            state.inputPersonalInfo = getPersonalDataEmptyObject()
            state.healthcareWorkerData = getHealthcareWorkerEmptyObject()
            state.inputBusinessActivity = []
            state.inputDescription = ""
            state.inputSchedule = {}
            state.selectedInfo = ""
            state.currentSection = 0
            state.remoteAvailableActivities = []
            state.remoteAvailableKeywords = []
            state.isDataConfirmed = false
            state.isLeavingConsent = false
            state.shouldSaveData = true
            state.samePersonFillForm = true
            state.doParticipate = associationPartnership.SI
            state.doConsent = "NO"
            state.doReadInitialInformation = false
            state.validationStatus = validationStatus.TO_VALIDATED
        },
        setFormRegistrationResult: (state,action) => {
            state.formRegistrationResult = action.payload
        },
        setRegistrationForModification:(state,action) => {
            state.registrationForModification = action.payload
        },
        setDoReadInitialInformation:(state, action)=>{
            state.doReadInitialInformation = action.payload
        },
        setDoConsent:(state, action)=>{
            state.doConsent = action.payload
        },
        setDoParticipate:(state, action)=>{
            state.doParticipate = action.payload
        },
        setValidationStatus:(state,action)=>{
            state.validationStatus = action.payload
        }
    }
})

export default registrationSlice.reducer

export const selectInputPersonalInfo = (state) => state.registrationForm.inputPersonalInfo;
export const selectInputBusinessActivity = (state) => state.registrationForm.inputBusinessActivity;
export const selectInputDescription = (state) => state.registrationForm.inputDescription;
export const selectCurrentSection = (state) => state.registrationForm.currentSection;
export const selectHealthcareWorkerData = (state) => state.registrationForm.healthcareWorkerData;
export const selectDoReadInitialInformation = (state) => state.registrationForm.doReadInitialInformation;
export const selectDoConsent = (state) => state.registrationForm.doConsent;
export const selectDoParticipate = (state) => state.registrationForm.doParticipate;

export const {
    setPersonalInformation,
    setBusinessActivity,
    addBusinessActivity,
    removeBusinessActivity,
    setDescription,
    setSchedule,
    setSelectedInfo,
    setCurrentSection,
    setAvailableActivities,
    setAvailableKeywords,
    setIsDataConfirmed,
    setIsLeavingConsent,
    setShouldSaveData,
    setHealthcareWorkerData,
    setSamePersonFillForm,
    cleanForm,
    setFormRegistrationResult,
    setRegistrationForModification,
    setDoReadInitialInformation,
    setDoConsent,
    setDoParticipate,
    setValidationStatus
} = registrationSlice.actions
