import { Container } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveBranchData, updateBranchInformation } from "./branchInformationSlice";
import Day from "./Day";
import {Strings} from "../../../assets/Strings";


const Week = ({ }) => {
    const days = Strings.it.days
    let branch = {...useSelector(selectActiveBranchData)}
    let schedule = {...branch.schedule}

    let daysData = [...schedule.days]
    const dispatch = useDispatch()
    const onValueChange = (dayName, dayData) => {
        daysData[days.indexOf(dayName)] = dayData
        schedule = {days:[...daysData]}
        dispatch(updateBranchInformation({...branch,schedule:schedule}))
    }

    const setInitialValue = (day) => daysData.filter((i)=>{return i.dayName === day})[0]
    return (
        <Container component={"div"}>
            {
                days
                    .map(
                        (item, index) => {
                            return <Day
                                initialValue={setInitialValue}
                                onValueChange={onValueChange}
                                item={item}
                                index={item}
                                key={index+Strings.it.days[index]}
                                name={Strings.it.days[index]}
                            />
                        }
                    )
            }
        </Container>
    )
}
export default Week;