import {createSlice} from "@reduxjs/toolkit";
import {NavigationPath} from "./navigationPath";
import {verifyToken} from "../validator/functions";
import {getValidatorProfileByCustomId} from "../healthcareProviderProfile/functions";

const navigationSlice = createSlice({
    name: 'navigation',
    initialState: {
        value: updateInitialPath(window.location.pathname),
        selectedAdminFormView: {email: "", custom_id: "not_found"},
        selectedPublicFormView: {email: "", custom_id: "not_found"},
        error: {error: "", title: "", details: ""}, backPath: "", forwardPath: ""
    },
    reducers: {
        navigateTo: (state, action) => {
            state.value = action.payload
        },
        navigateToAdminDetailsView: (state, action) => {
            state.selectedAdminFormView = action.payload
        },
        navigateToPublicDetailsView: (state, action) => {
            state.selectedPublicFormView = action.payload
        },
        navigateToError: (state, action) => {
            state.error = action.payload
        }
    }
})

export const selectNavigation = (state) => state.navigation.value;

export const {
    navigateTo,
    navigateToError
} = navigationSlice.actions
export default navigationSlice.reducer

export function updateInitialPath(pathname) {
    switch (pathname) {
        case "/":
            return NavigationPath.LANDING_PAGE
        case "/login":
            return NavigationPath.VALIDATOR_LOGIN
        case "/logout":
            return NavigationPath.VALIDATOR_LOGIN
        case "/census/register":
            return NavigationPath.FORM_REGISTRATION
        case "/obiettivo":
            return NavigationPath.OBIETTIVO
        case "/contact":
            return NavigationPath.CONTACT
        case "/about":
            return NavigationPath.ABOUT
        case "/validator/panel":
            return NavigationPath.VALIDATOR_PANEL
        case "/census/profiles/search":
            return NavigationPath.ADVANCED_SEARCH
        case "/census/profiles/:id":
            return NavigationPath.REGISTERED_ACTIVITY
        case "/census/register/result":
            return NavigationPath.FORM_REGISTRATION_RESULT
        case "/validator/panel/profiles/edit:id":
            verifyToken(
                null,
                ()=>{
                    return NavigationPath.EDIT_PROFILE
                },
                ()=>{return NavigationPath.VALIDATOR_LOGIN})
            break;
        case "/validator/panel/profiles/:id":
            return NavigationPath.REGISTERED_ACTIVITY_VALIDATOR_VIEW
        case "/support":
            return NavigationPath.SUPPORT
        default:
            return NavigationPath.NOT_FOUND
    }
}
