import { Pagination } from "@mui/material"
import {Styles} from "../../design/styles"
const Paginator = ({totalPage,handlePageChange,currentPage}) => {
    return (
        <Pagination
        {...Styles.Paginator.Props}
        sx={Styles.Paginator.Pagination}
        count={parseInt(totalPage)||1}
        page={parseInt(currentPage)||1}
        onChange={handlePageChange}
      />
    )
}

export default Paginator;