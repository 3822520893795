import {useDispatch, useSelector} from "react-redux";
import {useQueriesHooks} from "../../global/mediaQueries";
import {useMediaQuery} from "react-responsive";
import Paginator from "../../commonComponents/Paginator";
import {Grid} from "@mui/material";
import Alert from "../../alert/Alert";
import ValidatorRegisteredProfile from "./ValidatorRegisteredProfile";
import {
    getValidatorData,
    selectSearchResultValidatorData, selectSearchValidatorCurrentPage,
    selectSearchValidatorNextPageUrl,
    selectSearchValidatorPreviousPageUrl, selectSearchValidatorTotalPage, setValidatorCurrentPage
} from "./validatorSearchResultSlice";

export default function ValidatorSearchView() {
    const dispatch = useDispatch()
    const data = useSelector(selectSearchResultValidatorData)
    const nextPageUrl = useSelector(selectSearchValidatorNextPageUrl)
    const previousPageUrl = useSelector(selectSearchValidatorPreviousPageUrl)
    const totalPage = useSelector(selectSearchValidatorTotalPage)
    const currentPage = useSelector(selectSearchValidatorCurrentPage)
    const {isPortrait} = useQueriesHooks(useMediaQuery)
    const handlePageChange = (_, value) => {
        dispatch(setValidatorCurrentPage(value))
        if (value > currentPage) { dispatch(getValidatorData(nextPageUrl)) }
        if (value < currentPage) { dispatch(getValidatorData(previousPageUrl)) }

    };
    return renderUI(data, totalPage, handlePageChange,isPortrait,currentPage);
};

function renderUI(data, totalPage, handlePageChange,isPortrait,currentPage) {

    return (
        <div
            style={{
                marginTop: "0",
                marginLeft:"auto",
                marginRight:"auto",
                marginBottom: "",
                backgroundColor: "",
                width: isPortrait?"":"90vw",
                justifyContent: "center",
            }}
        >
            <Paginator totalPage={totalPage} handlePageChange={handlePageChange} currentPage={currentPage}/>
            <Grid
                container
                rowSpacing={0}
                columnSpacing={{xs: 1, sm: 1, md: 2}}
                padding={1}
                justifyContent="center"
                style={{
                    overflow: "auto",
                    minHeight:"40vh"
                }}
            >
                {data.length === 0 ? <Alert type="info" msg="nessun profilo da visualizzare"/> : data
                    .map((p, index) => {
                        let item = {...p}
                        item.title = p.activityName;
                        item.subTitle = p.referName?p.referName:"-";
                        return <ValidatorRegisteredProfile key={index} item={item}/>;
                    })}
            </Grid>
        </div>
    );
}
