import { createSlice } from "@reduxjs/toolkit";
import {LoginStatus} from "./loginStatus";


export function createEmptyAccount(){
    return {
        id: -1,
        password: "",
        last_login: "-",
        is_superuser: false,
        username: "",
        is_active: false,
        is_staff: false,
        email: "",
        groups: [],
        user_permissions: []
    }
}

const authSlice = createSlice({
    name: "auth",
    initialState: {
        token:"not_a_token",
        refreshToken:"",
        account: createEmptyAccount(),
        loginStatus:LoginStatus.LOG_OUT
    },
    reducers: {
        setAuthTokens:(state, action)=> {
            sessionStorage.setItem("token", action.payload.token);
            sessionStorage.setItem("refreshToken", action.payload.refreshToken);
            state.refreshToken = action.payload.refreshToken;
            state.token = action.payload.token;
        },
        setAccount:(state, action)=> {
            sessionStorage.setItem("account", JSON.stringify(action.payload));
            state.account = action.payload;
        },
        logout:(state) =>{
            state.refreshToken = "";
            state.token = "";
            state.account = createEmptyAccount();
            sessionStorage.setItem("token", "");
            sessionStorage.setItem("refreshToken", "");
            sessionStorage.setItem("account", JSON.stringify(createEmptyAccount()));
        },
        setLoginStatus:(state,action)=>{
            state.loginStatus = action.payload
        }
    },
});

export default authSlice.reducer;
export const selectAccount = (state) => state.auth.account;
export const selectToken = (state) => state.auth.token;
export const selectLoginStatus = (state) => state.auth.loginStatus;

export const {
    setAuthTokens,
    setAccount,
    logout,
    setLoginStatus
} = authSlice.actions
