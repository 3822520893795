import { Button, Container, Grid, CardActions, Paper } from "@mui/material"
import { useDispatch } from "react-redux"
import { mainActions } from "../../util/const"
import { navigateTo } from "../navigation/navigationSlice"
import { NavigationPath } from "../navigation/navigationPath"
import {Strings} from "../../assets/Strings";

const LandingPageSheet = () => {

  let dispatch = useDispatch()
  const onClick = (elementName) => {
    if (elementName === "Register") { dispatch(navigateTo(NavigationPath.FORM_REGISTRATION)) }
    if (elementName === "Explore") { dispatch(navigateTo(NavigationPath.ADVANCED_SEARCH)) }
  }
  return _renderUI(onClick)
}

export default LandingPageSheet

function _renderUI(onClick) {
  return (
      <div style={{paddingBottom: "2rem", height: "100%", paddingTop: "78px"}}>
        <Container className="root-container">
          <Paper sx={{marginLeft: "auto", marginRight: "auto", display: "block", padding: "1rem"}}>
            <h1
                style={{
                  margin: "2rem", textAlign: "justify"
                }}
            >{Strings.it.landingSheet.title}</h1>
            <div style={{overflow: "auto", overflowY: "auto", maxHeight: "400px"}}>
              <p
                  style={{
                    margin: "2rem", textAlign: "justify"
                  }}
              >{Strings.it.landingSheet.content}</p>

            </div>
            <Grid className="sample-grid" container spacing={3} sx={{backgroundColor: ""}}>
              {mainActions.map((elem) => (
                  <Grid
                      className="feedback-requesters-grid"
                      container item xs={5} key={elem.name}
                      style={{
                        margin: "10px"
                      }}
                  >
                    <CardActions>
                      <Button size="small" color="primary"
                              onClick={() => {onClick(elem.name)}}
                              sx={elem.name === "Register"
                                  ? {backgroundColor: "#582447", color: "white", margin: 0}
                                  : {backgroundColor: "#f7f7f7", color: "black", margin: 0}
                              }>{elem.name === "Register" ? Strings.it.registerActivity : Strings.it.searchForActivity}</Button>
                    </CardActions>
                  </Grid>
              ))}
            </Grid>
          </Paper>
        </Container>
      </div>
  );
}