import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {toggle} from './drawerSlice';
import {Styles} from '../../design/styles';
import {store} from "../../store/store";

export const HamburgerMenu = () => {

    return (
        <IconButton
            {...Styles.HamburgerMenu.IconButton.Props}
            sx={Styles.HamburgerMenu.IconButton}
            onClick={() => store.dispatch(toggle())}
        >
            <MenuIcon/>
        </IconButton>
    );
}