import { Chip, Grid, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addWordToKeywords, selectWordToKeyword } from "../../commonComponents/richTextField/richTextFieldSlice";
import { selectFilteredSuggestedKeywords, updateSearchQuery } from "./suggestedKeywordsSlice";
import {Box} from "@mui/system";

const SuggestedKeywordsContainer = () => {
    let suggestedKeywords = useSelector(selectFilteredSuggestedKeywords)
    const selectedKeys = useSelector(selectWordToKeyword)
    const dispatch = useDispatch()
    return <div style={{margin:""}}>
        <Grid container>
            <Grid item xs={12} md={12} sx={{backgroundColor:""}}>
            <Typography variant="body2" sx={{marginLeft:3,marginBottom:1}} >Scegli dalla lista qui sotto la/le categoria/e a cui fanno riferimento le attività o i servizi che la tua organizzazione propone al territorio, oppure inserisci nuove categorie nella finestra qui di fianco.</Typography>
                <TextField
                    sx={{ height :"50px", marginBottom:"1rem",marginLeft:3 }}
                    type="text"
                    label="cerca..."
                    variant="outlined"
                    onSelect={() => { }}
                    onChange={(e) => { dispatch(updateSearchQuery(e.target.value))}}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                    }}
                    component="ul"
                >
                    <Grid container sx={{ maxHeight: "27vh",overflow:"auto",backgroundColor:"",paddingLeft:3 }}>
                        {suggestedKeywords.slice(0,15).map((item) => {
                            return (
                                <Grid item xs={4} md={4} key={item.word} justifyContent="center">
                                    <Chip label={item.word} onClick={() => { if(selectedKeys.length < 5) dispatch(addWordToKeywords(item.word)) }} sx={{ margin: "2px" }} />
                                </Grid>
                            );
                        })}
                    </Grid>

                </Box>
            </Grid>
        </Grid>

    </div>
}

export default SuggestedKeywordsContainer;
