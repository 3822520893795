import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#3b1326',
      darker: '#16080F',
    },
    secondary:{
      main:"#d33c9f",
      darker:"#9b188a"
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
});

export default theme
