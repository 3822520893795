import * as React from "react";
import {store} from "../../../store/store";
import {navigateTo} from "../../navigation/navigationSlice";
import {setCurrentEntryToExpand} from "../../validator/validatorSectionSlice";
import {NavigationPath} from "../../navigation/navigationPath";
import {Container, Grid, Popover} from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import KeywordChip from "../../registrationForm/DescriptionDataSection/KeywordChip";
import {CHIP_COLORS} from "../../../design/colors";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import {validationStatus} from "../../../util/const";
import {requestDeletion, requestValidation} from "../../../networkEngine/functions";
import {setValidatorNeedUpdate} from "./validatorSearchResultSlice";

export default function ValidatorRegisteredProfile({item}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = store.dispatch
    let words = item.keywords.map((w) => {
        return w.word
    })
    let acta = item.activities.map((w) => {
        return w.name
    })
    const isValidated = (item.validationStatus === validationStatus.ACCEPTED)
    words = acta.concat(words)
    const onDetailsClick = () => {
            dispatch(setCurrentEntryToExpand(item))
            dispatch(navigateTo(NavigationPath.REGISTERED_ACTIVITY_VALIDATOR_VIEW))
    }
    const onShareClick = (e) => {
        navigator.clipboard.writeText(`https://${window.location.hostname}:${window.location.port}/census/profiles/${item.custom_id}`);
        if (anchorEl !== null) {
            setAnchorEl(null);
        } else {
            setAnchorEl(e.currentTarget);
        }
    }
    if (isValidated) {
        return (<Grid item sx={{padding: "5px"}}>
            <Container>
                <Card sx={{
                    maxWidth: 320,
                    minWidth: 320,
                    maxHeight: 320,
                    minHeight: 320,
                    position: "relative",
                    backgroundColor: "RGB(222,222,202,.1)"
                }}>
                    { <ValidatorOverlaidOptions item={item} alreadyValidated={isValidated}
                    />}
                    <Grid container spacing={1} direction={"column"} justifyContent={"center"}
                          justifyItems={"center"} alignItems={"center"}>

                        <Grid item sx={{marginTop:"4px",borderRadius:"8px"}}>
                            <div style={{backgroundColor: "RGB(221,191,221,.1)",padding:"4px",borderRadius:"4px"}}>
                                <Typography  gutterBottom component={"p"} variant="body2" noWrap={false} textOverflow={"ellipsis"}
                                             sx={{
                                                 display: '-webkit-box',
                                                 textOverflow: "ellipsis",
                                                 overflow: 'hidden',
                                                 WebkitBoxOrient: 'vertical',
                                                 WebkitLineClamp: 2,
                                                 fontWeight: "bold",maxWidth:"300px"}}>
                                    {item.title}
                                </Typography>
                            </div>
                        </Grid>

                        <Grid item sx={{height: "170px", backgroundColor: "", overflowY: "auto"}}>
                            <Grid container direction={"column"} justifyContent={"center"}
                                  justifyItems={"center"} alignItems={"center"}>
                                {words.slice(0, 3).map((w, index) => {
                                    return (<Grid item xs={12}>
                                            <KeywordChip variant={"p"} item={w} overFlow={false}
                                                         maxWidth={"220px"} color={CHIP_COLORS[index]}
                                                         textColor={"white"}/></Grid>
                                    )
                                })
                                }

                            </Grid>
                        </Grid>

                        <CardActions sx={{position: "absolute", bottom: "0"}}>
                            <Button size="small" onClick={onShareClick}>condividi</Button>
                            <Popover
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                onClose={() => setAnchorEl(null)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <Typography sx={{p: 2}}>L'indirizzo è stato copiato in memoria.</Typography>
                            </Popover>
                            <Button size="small" onClick={onDetailsClick}>dettagli</Button>
                        </CardActions></Grid>

                </Card></Container></Grid>);
    } else {
        return (<Grid item sx={{padding: "5px"}}>
            <Container>
                <Card sx={{maxWidth: 320, minWidth: 320, maxHeight: 320, minHeight: 320, position: "relative",
                    backgroundColor: "RGB(222,222,202,.1)"}}>
                    <ValidatorOverlaidOptions item={item} alreadyValidated={isValidated}/>
                    <Grid container spacing={1} direction={"column"} justifyContent={"center"}
                          justifyItems={"center"} alignItems={"center"}>

                        <Grid item sx={{marginTop:"4px",borderRadius:"8px"}}>
                            <div style={{backgroundColor: "RGB(221,191,221,.1)",padding:"4px",borderRadius:"4px"}}>
                                <Typography  gutterBottom component={"p"} variant="body2" noWrap={false} textOverflow={"ellipsis"}
                                             sx={{
                                                 display: '-webkit-box',
                                                 textOverflow: "ellipsis",
                                                 overflow: 'hidden',
                                                 WebkitBoxOrient: 'vertical',
                                                 WebkitLineClamp: 2,
                                                 fontWeight: "bold",maxWidth:"300px"}}>
                                    {item.title}
                                </Typography>
                            </div>
                        </Grid>

                        <Grid item sx={{height: "170px", backgroundColor: "", overflowY: "auto"}}>
                            <Grid container direction={"column"} justifyContent={"center"}
                                  justifyItems={"center"} alignItems={"center"}>
                                {words.slice(0, 3).map((w, index) => {
                                    return (<Grid item xs={12}>
                                            <KeywordChip variant={"p"} item={w} overFlow={false}
                                                         maxWidth={"220px"} color={CHIP_COLORS[index]}
                                                         textColor={"white"} /></Grid>
                                    )
                                })
                                }

                            </Grid>
                        </Grid>

                        <CardActions sx={{position: "absolute", bottom: "0"}}>
                            <Button size="small" onClick={onShareClick}>condividi</Button>
                            <Popover
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                onClose={() => setAnchorEl(null)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <Typography sx={{p: 2}}>L'indirizzo è stato copiato in memoria.</Typography>
                            </Popover>
                            <Button size="small" onClick={onDetailsClick}>dettagli</Button>
                        </CardActions></Grid>
                </Card></Container></Grid>);
    }
}

export function ValidatorOverlaidOptions(
    {
        item, alreadyValidated
    }
) {
    const handleRequestValidation = () => {
        requestValidation(item, validationStatus.ACCEPTED)
        store.dispatch(setValidatorNeedUpdate(true))
    }
    const handleRequestDelete = () => {
        requestDeletion(item.email)
        store.dispatch(setValidatorNeedUpdate(true))
    }
    return (
        <Grid container spacing={12} padding={1} justifyContent={"center"}>
            {alreadyValidated ?
                <div></div> : <Grid item> <Button onClick={() => handleRequestValidation()}
                                                  sx={{backgroundColor: "RGB(147,17,17,.3)"}}>Valida</Button></Grid>

            }
            <Grid item>
                <Button onClick={() => handleRequestDelete()}
                                     sx={{backgroundColor: "RGB(147,17,17,.3)"}}>Rimuovi</Button>
            </Grid>
        </Grid>
    )
}
