import { TimePicker } from '@mui/x-date-pickers';
import { TextField } from "@mui/material"
import { useState } from 'react';

const HourPicker = ({ label,onValueChange,fieldType,initialValue }) => {
    const[time,setTime] = useState(initialValue)
    const handleChange = (value,_) => { 
        setTime(value)
        onValueChange(fieldType,value)
     }
    return (
        <div style={{ margin: "4px", display: "inline-block" }}>
            <TimePicker
                label={label}
                value={time}
                ampm={false}
                onChange={handleChange}
                renderInput={
                    (params) =>
                        <TextField
                            {...params}
                            sx={{ width: "140px", height: "50px" }}
                            id="margin-dense"
                            margin="dense"
                            size="small"
                            onKeyDown={(e) => e.preventDefault()}
                        />
                }
            />
        </div>
    )
}

export default HourPicker;
