import { createSlice } from "@reduxjs/toolkit";

const validatorSectionSlice = createSlice({
    name: 'validatorSection',
    initialState: {
        currentUser: undefined,
        loginError:{error:"",title:"",details:""},
        toValidates:[],
        currentEntryToExpand: null,
        validationError: {title:""},
        isOnModificationSection:false,
        requestedProfile: null
    },
    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload
        },
        setLoginError: (state,action) => {
            state.loginError = action.payload
        },
        setToValidates:(state,action) => {
            state.toValidates = action.payload
        },
        setCurrentEntryToExpand: (state,action) => {
            state.currentEntryToExpand = action.payload
        },
        setValidationError: (state,action) => {
            state.validationError = action.payload
        },
        setIsOnModificationSection:(state,action) => {
            state.isOnModificationSection = action.payload
        },
        setRequestedProfile:(state,action)=>{
            state.requestedProfile = action.payload
        }
    }
})

export default validatorSectionSlice.reducer

export const selectLoginError = (state) => state.validatorSection.loginError;
export const selectCurrentEntryToExpand = (state) => state.validatorSection.currentEntryToExpand;
export const selectIsOnModificationSection = (state) => state.validatorSection.isOnModificationSection;
export const selectRequestedProfile = (state) => state.validatorSection.requestedProfile;

export const {
    setCurrentUser,
    setLoginError,
    setToValidates,
    setCurrentEntryToExpand,
    setValidationError,
    setIsOnModificationSection,
    setRequestedProfile
} = validatorSectionSlice.actions