import {Grid} from "@mui/material";
import PublicSearchFilter from "./PublicSearchFilter";
import PublicSearchView from "./PublicSearchView";

export function PublicAdvancedSearch() {
    return (
        <Grid container sx={{marginTop: "0"}}>
            <Grid item xs={12} md={12}><PublicSearchFilter/></Grid>
            <Grid item xs={12} md={12}><PublicSearchView/></Grid>
        </Grid>
    )
}