import {Box, Card, CardContent, Grid, Typography} from "@mui/material";
import {Styles} from "../../design/styles";
import {capitalizeFirstLetter} from "../../util/functions";

import ActivityDetailsComponent from "./ActivityDetailsComponent";
import {Strings} from "../../assets/Strings";
import * as React from "react";

export default function PersonalDataComponent({profile, editable}) {
    const description = profile.description ? profile.description : Strings.it.descriptionNotProvided
    return (
        <div style={{paddingTop: "16px"}}>
            <Box
                elevation={1}>
                <div style={{display: "inline"}}>
                    <Card sx={{
                        display: "inline-block",
                        marginLeft: "16px",
                        padding: "8px",
                        backgroundColor: "RGB(223,230,230,.2)"
                    }}>
                        <Typography variant={"h6"} sx={Styles.HealthcareProviderProfile.PersonalDataComponent.Header}>
                            {`${capitalizeFirstLetter(profile.activityName)}`}
                        </Typography>
                        {(profile.referName && profile.referName !== "-") ?
                            <Typography variant="body3" color="text.secondary">
                                {profile.referName}
                            </Typography> : <></>}
                    </Card>
                </div>

                <CardContent>
                    <Grid container spacing={8}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Card sx={{
                                display: "inline-block",
                                marginLeft: "",
                                padding: "8px",
                                backgroundColor: "RGB(223,230,230,.2)"
                            }}>
                                {
                                    profile.email
                                        ? <Typography display="inline" sx={Styles.HealthcareProviderProfile.PersonalDataComponent.Header}
                                        >{`Email del referente: ${profile.email} | `}
                                        </Typography>
                                        : <></>

                                }
                                <Typography display="inline" sx={Styles.HealthcareProviderProfile.PersonalDataComponent.Header}
                                >{`Email operativa: ${profile.operativeEmail?profile.operativeEmail:"-"}`}
                                </Typography>
                                <br />
                                {
                                    profile.phoneNumber
                                        ? <Typography display="inline" sx={Styles.HealthcareProviderProfile.PersonalDataComponent.Header}
                                        >{`${Strings.it.phone}: ${profile.phoneNumber} | `}</Typography>
                                        : <></>
                                }


                                <Typography display="inline" sx={Styles.HealthcareProviderProfile.PersonalDataComponent.Header}
                                >{`${Strings.it.operativePhoneNumber}: ${profile.operativePhoneNumber?profile.operativePhoneNumber:"-"}`}
                                </Typography>
                                <br />
                                {
                                    profile.website
                                        ? <Typography display="inline" sx={Styles.HealthcareProviderProfile.PersonalDataComponent.Header}
                                        >{`${profile.website}`}
                                        </Typography>
                                        : <></>
                                }

                            </Card>
                            <div style={{ display: "flex" }}>
                                <div style={{
                                    backgroundColor: "RGB(155,155,155,.1)",
                                    borderRadius: "0px 12px 12px 12px",
                                    padding: "12px",
                                    marginTop: "16px"
                                }}>
                                    <Typography sx={{ marginTop: "0px" }}>
                                        {description}
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}><ActivityDetailsComponent profile={profile || {}}
                            editable={false}
                            colorRandom={false} /></Grid>
                    </Grid>
                </CardContent>
            </Box>
        </div>
    )
}
