export const AcceptablePaths={
    ROOT:"/",
    LOGIN:"/login",
    REGISTER_RESULT:"/census/register/result",
    REGISTER_PROFILE:"/census/register",
    PUBLIC_PROFILE_SEARCH:"/census/profiles/search",
    PUBLIC_PROFILE_VIEW:"/census/profiles/:id",
    VALIDATOR_PANEL:"/validator/panel",
    VALIDATOR_PROFILE_VIEW:"/validator/panel/profiles/:id",
    VALIDATOR_EDIT_PROFILE:"/validator/panel/profiles/edit/:id",
    OBIETTIVO:"/obiettivo",
    NEWS:"/news",
    CONTACT:"/contact",
    ABOUT:"/about",
    SUPPORT:"/support",

}