import { createSlice } from "@reduxjs/toolkit";
import { ApiConnectionStatus } from "../registrationForm/const";
import {Strings} from "../../assets/Strings";



const apiConnectorIndicatorSlice = createSlice({
    name: 'apiConnectionIndicator',
    initialState: { value: ApiConnectionStatus.IDLE, details:Strings.it.loading },
    reducers: {
        setApiConnectionStatus: (state,action) => {
            state.value = action.payload
        },
        setApiConnectionStatusDetails: (state,action) => {
            state.details = action.payload
        }
    }
})

export const selectApiConnectorIndicator = (state) => state.apiConnectionIndicator.value;
export const selectApiConnectorIndicatorDetails = (state) => state.apiConnectionIndicator.details;

export const { setApiConnectionStatus,setApiConnectionStatusDetails } = apiConnectorIndicatorSlice.actions
export default apiConnectorIndicatorSlice.reducer

