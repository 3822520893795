import {API} from "../../networkEngine/api";
import {NETWORK} from "../../networkEngine/NETWORK";
import {store} from "../../store/store";
import {
    setApiConnectionStatus,
    setApiConnectionStatusDetails
} from "../apiConnectionIndicator/apiConnectionIndicatorSlice";
import {NavigationPath} from "../navigation/navigationPath";
import {navigateTo} from "../navigation/navigationSlice";
import {ApiConnectionStatus} from "../registrationForm/const";
import {Strings} from "../../assets/Strings";
import {
    setPublicCurrentPage,
    setPublicData,
    setPublicNextPage,
    setPublicPreviousPage,
    setPublicTotalPage
} from "./public/publicSearchResultSlice";
import {
    setValidatorCurrentPage,
    setValidatorData,
    setValidatorNextPage,
    setValidatorPreviousPage,
    setValidatorTotalPage
} from "./validator/validatorSearchResultSlice";
import {logout, setLoginStatus} from "../validator/authSlice";
import {LoginStatus} from "../validator/loginStatus";

export function paginatePublicProfileData(response) {
    store.dispatch(setPublicData(response.data.results))
    store.dispatch(setPublicNextPage(response.data.next))
    store.dispatch(setPublicPreviousPage(response.data.previous))
    store.dispatch(setPublicTotalPage(Math.ceil(response.data.count / 10)))
}
export function searchPublicProfiles(searchParams,url=API.publicSearch) {
    if (url===API.publicSearch) store.dispatch(setPublicCurrentPage(1))
    store.dispatch(setApiConnectionStatus(ApiConnectionStatus.LOADING))
    NETWORK
        .publicAll(url, searchParams)
        .then((response) => {
            paginatePublicProfileData(response)
            store.dispatch(setApiConnectionStatus(ApiConnectionStatus.SUCCEED))
        }).catch((e) => {
        store.dispatch(setApiConnectionStatusDetails(Strings.it.connectionProblem));
        store.dispatch(setApiConnectionStatus(ApiConnectionStatus.FAILED))
    })

    store.dispatch(setApiConnectionStatus(ApiConnectionStatus.IDLE))
}

export function paginateValidatorProfileData(response) {
    store.dispatch(setValidatorData(response.data.results))
    store.dispatch(setValidatorNextPage(response.data.next))
    store.dispatch(setValidatorPreviousPage(response.data.previous))
    store.dispatch(setValidatorTotalPage(Math.ceil(response.data.count / 10)))
}
export function searchValidatorProfiles(token, searchParams,url=API.validatorSearch) {
    if (url===API.validatorSearch) store.dispatch(setValidatorCurrentPage(1))
    store.dispatch(setApiConnectionStatus(ApiConnectionStatus.LOADING))
    const config = {headers: {Authorization: `Bearer ${token}`}};
    NETWORK
        .validatorAll(url, searchParams, config)
        .then((response) => {
            paginateValidatorProfileData(response)
            store.dispatch(setApiConnectionStatus(ApiConnectionStatus.SUCCEED))
        }).catch((e) => {
        store.dispatch(setApiConnectionStatusDetails(Strings.it.connectionProblem));
        store.dispatch(setApiConnectionStatus(ApiConnectionStatus.FAILED))
        store.dispatch(logout())
        store.dispatch(setLoginStatus(LoginStatus.LOG_OUT))
        store.dispatch(navigateTo(NavigationPath.VALIDATOR_LOGIN))
    })

    store.dispatch(setApiConnectionStatus(ApiConnectionStatus.SUCCEED))
}

export function createEmptyFilterParams(){
    return {
        activity: "",
        keyword: "",
        referName: "",
        activityName: "",
        address: ""
    }
}
