import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {store} from "../../store/store";
import {NavigationPath} from "../navigation/navigationPath";
import {navigateTo, selectNavigation} from "../navigation/navigationSlice";
import {selectToken} from "../validator/authSlice";
import {selectCurrentEntryToExpand} from "../validator/validatorSectionSlice";
import {getValidatorProfileByCustomId} from "./functions";
import React, {useEffect} from "react";
import {Card, Grid} from "@mui/material";
import {Styles} from "../../design/styles";
import NotFound from "../404/NotFound";
import PersonalDataComponent from "./PersonalDataComponent";
import BranchListComponent from "./BranchListComponent";
import {requestValidation} from "../../networkEngine/functions";
import {validationStatus} from "../../util/const";
import {UserPanel} from "../editableHealthcareProviderProfile/EditableHealthcareProviderProfile";

export default function HealthcareProviderProfileValidatorView() {
    const param = useParams()
    const composeOnFinishEdit = () => {
        store.dispatch(navigateTo(NavigationPath.EDIT_PROFILE))
    }
    const doValidation = () => {
        requestValidation(profile, validationStatus.ACCEPTED)
    }
    let token = useSelector(selectToken)
    if (token === "not_a_token") token = sessionStorage.getItem("token")
    useEffect(() => {
        getValidatorProfileByCustomId(token, param.id)
    }, [token, param.id])
    const profile = useSelector(selectCurrentEntryToExpand)
    const cp = useSelector(selectNavigation)

    if (profile) {
        return (
            <Card
                sx={{
                    backgroundColor: "RGB(85,85,25,.1)",
                    position: "relative",
                    width: "70%",
                    height: "70vh",
                    marginRight: "auto",
                    marginLeft: "auto",
                    marginTop: "16px",
                    borderRadius: "5px",
                    overflowY: "auto",
                    overflowX: "hidden",
                }}
                elevation={1}
            >
                <UserPanel cp={cp} composeOnFinishEdit={composeOnFinishEdit} profile={profile} doValidation={doValidation}/>
                <div
                    style={{backgroundColor: "",}}
                >
                    <Grid
                        {...Styles.FormPanel.SectionHolder.Props}
                        sx={Styles.FormPanel.SectionHolder.Style}
                    >
                        <ValidatorOverview profile={profile} composeOnFinishEdit={composeOnFinishEdit}
                                           doValidation={doValidation}/>
                    </Grid>
                </div>
            </Card>
        );
    } else {
        return <NotFound/>
    }

}

function ValidatorOverview({profile}) {
    return (
        <Grid
            container justifyContent={"center"} alignItems={"center"}
        >
            <Grid sx={{backgroundColor: ""}} item xs={12} md={12}><PersonalDataComponent profile={profile || {}}
                                                                                         editable={false}
                                                                                         isValidator={false}/></Grid>
            <Grid sx={{backgroundColor: ""}} item xs={10} md={10}><BranchListComponent branches={profile.Branches?profile.Branches: []}
                                                                                       editable={false}/></Grid>
        </Grid>
    )
}