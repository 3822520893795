import { createSlice } from "@reduxjs/toolkit";

const infoDialogSlice = createSlice({
    name: 'infoDialog',
    initialState: { value: false },
    reducers: {
        toggleActive: (state) => {
            state.value = !state.value
        },
        close: (state)=>{
            state.value = false
        }
    }
})

export default infoDialogSlice.reducer
export const selectInfoDialog = (state) => state.infoDialog.value;
export const { toggleActive,close } = infoDialogSlice.actions