import React from 'react';
import {Box, Container, Grid} from '@mui/material';
import {Styles} from '../../design/styles';
import {FooterMenus} from '../../util/const';
import {FooterLinkSection} from './FooterLinkSection';
import {CopyRightSection} from "./FooterItem";

const Footer = () => {
    return (
        <footer>
            <Box
                {...Styles.Footer.Box.Props}
                sx={{...Styles.Footer.Box.Style}}
            >
                <Container>
                    <Grid container spacing={2}>
                        {
                            FooterMenus.map(
                                (section) => <FooterLinkSection section={section} key={section.title}/>)
                        }
                    </Grid>
                    <CopyRightSection/>
                </Container>
            </Box>
        </footer>
    );
};

export default Footer;
