import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { NavigationPath } from '../features/navigation/navigationPath';
import LogoutIcon from '@mui/icons-material/Logout';
import {Strings} from "../assets/Strings";
import LoginIcon from "@mui/icons-material/Login";

export const phoneRegex = /^[0-9()-]{3,14}$/
export const namesRegex = /(.*[a-z])/i
export const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
export const webRegex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

export const validationStatus = {
    TO_VALIDATED:"TO_VALIDATED",
    ACCEPTED:"ACCEPTED",
}
export const associationPartnership = {
    SI:"SI",
    NO:"NO"
}
export const menuItems = [
    {
        id: NavigationPath.LANDING_PAGE,
        name: Strings.it.homePage,
        icon: HomeOutlinedIcon
    },
    {
        id: NavigationPath.FORM_REGISTRATION,
        name: Strings.it.registerYourData,
        icon: DriveFileRenameOutlineOutlinedIcon
    },
    {
        id: NavigationPath.ADVANCED_SEARCH,
        name: Strings.it.advancedSearch.title,
        icon: ManageSearchIcon
    },
    {
        id: NavigationPath.CONTACT,
        name: Strings.it.contactUs,
        icon: ContactPhoneOutlinedIcon
    },
    {
        id: NavigationPath.ABOUT,
        name: Strings.it.whoWeAre,
        icon: InfoOutlinedIcon
    },
    {
        id: NavigationPath.VALIDATOR_PANEL,
        name: Strings.it.reservedArea,
        icon: LoginIcon
    },
    {
        id:NavigationPath.LOG_OUT,
        name:Strings.it.logout,
        icon: LogoutIcon
    }
]

export const mainActions = [
    {
        name: Strings.it.register,
        icon: HomeOutlinedIcon
    },
    {
        name: Strings.it.explore,
        icon: DriveFileRenameOutlineOutlinedIcon
    },
]

export const FooterMenus = [
    {
        title: Strings.it.ourGoal,
        items: [
            {title: Strings.it.ourGoal, navigationPath: NavigationPath.OBIETTIVO},
            {title: Strings.it.whoWeAre, navigationPath: NavigationPath.ABOUT},
        ]
    },
    {
        title: Strings.it.forWhoWorksWithUs,
        items: [
            {title: Strings.it.reservedArea,navigationPath: NavigationPath.VALIDATOR_PANEL},
        ]
    }
]

export const logo = "/assets/sds.jpg"
