import { createSlice } from "@reduxjs/toolkit";

const drawerSlice = createSlice({
    name: 'drawer',
    initialState: { value: false },
    reducers: {
        open: (state) => {
            state.value = true
        },
        close: (state) => {
            state.value = false
        },
        toggle: (state) => {
            state.value = !state.value
        }
    }
})

export const selectDrawer = (state) => state.drawer.value;

export const { open, close, toggle } = drawerSlice.actions
export default drawerSlice.reducer