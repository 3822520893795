export const NavigationPath = {
	LANDING_PAGE: 0,
    ADVANCED_SEARCH:1,
	FORM_REGISTRATION: 2,
	ABOUT: 3,
	CONTACT: 4,
    OBIETTIVO: 5,
    ADMIN_PANEL:7,
    API_PANEL:8,
    SUPPORT:9,
    VALIDATOR_LOGIN:10,
    VALIDATOR_REGISTER:11,
    REGISTERED_ACTIVITY:12,
    VALIDATOR_PANEL:13,
    FORM_REGISTRATION_RESULT:14,
    EDIT_PROFILE:6,
    REGISTERED_ACTIVITY_VALIDATOR_VIEW:16,
    NOT_FOUND:17,
    LOG_OUT:18
}