import {useDispatch, useSelector} from "react-redux";
import {setDoConsent, setDoReadInitialInformation} from "../registrationForm/registrationSlice";
import {selectCurrentEntryToExpand, setIsOnModificationSection} from "../validator/validatorSectionSlice";
import FormPanel from "../registrationForm/FormPanel";
import {Box} from "@mui/system";
import {Grid, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {store} from "../../store/store";
import {navigateTo, selectNavigation} from "../navigation/navigationSlice";
import {NavigationPath} from "../navigation/navigationPath";
import React, {useEffect} from "react";
import {requestValidation} from "../../networkEngine/functions";
import {validationStatus} from "../../util/const";
import {getValidatorProfileByCustomId} from "../healthcareProviderProfile/functions";
import {useParams} from "react-router-dom";
import {handleProfileEditRequest} from "../registrationForm/functions";

export function UserPanel(cp, composeOnFinishEdit, profile, doValidation) {
    return <>
        <Box
            onMouseEnter={{color: "wheat"}}
            sx={{
                marginTop: "120px",
                left: 0, borderRadius: "0 10px 10px 0",
                color: "white",
                marginLeft: "8px", position: "fixed",
                zIndex: 12, backgroundColor: "RGB(117,17,57,.9)",
                minWidth: "130px", minHeight: "70px",padding:"4px",
            }}>
            <Grid
                sx={[NavigationPath.EDIT_PROFILE, 17].includes(cp) ? {backgroundColor: "RGB(120,90,90,1)"} : {}}><Button
                onClick={() => {store.dispatch(navigateTo(NavigationPath.EDIT_PROFILE))}} title="modifica" size="small"
                sx={{
                    zIndex: "10",
                    top: 0,
                    left: 0,
                    color: [NavigationPath.EDIT_PROFILE, 17].includes(cp) ? "RGB(117,17,57,.9)" : "white"
                }}>
                Modifica dati</Button></Grid>
            {
                profile && profile.validationStatus === validationStatus.TO_VALIDATED
                    ? <Grid> <Button onClick={() => doValidation()} title="valida" size="small"
                                     sx={{zIndex: "10", top: 0, left: 0, color: "white"}}>Valida</Button></Grid>
                    : <></>
            }
            <Grid><Button onClick={() => store.dispatch(navigateTo(NavigationPath.VALIDATOR_PANEL))}
                          title="torna alla lista" size="small"
                          sx={{zIndex: "10", top: 0, left: 0, color: "white"}}>
                Torna alla lista</Button></Grid>
        </Box>
    </>;
}

export default function EditableHealthcareProviderProfile() {
    const dispatch = useDispatch()
    const profile = useSelector(selectCurrentEntryToExpand)
    const cp = useSelector(selectNavigation)
    const param = useParams()
    if (!profile) {
        getValidatorProfileByCustomId(sessionStorage.getItem("token"), param.id)
    }

    const composeOnFinishEdit = () => {
        store.dispatch(navigateTo(NavigationPath.EDIT_PROFILE))
    }
    const doValidation = () => {
        requestValidation(profile, validationStatus.ACCEPTED)
    }
    dispatch(setIsOnModificationSection(true))
    dispatch(setDoReadInitialInformation(true))
    dispatch(setDoConsent("SI"))
    return (
        <div style={{position: "relative"}}>
            <UserPanel cp={cp} composeOnFinishEdit={composeOnFinishEdit} profile={profile} doValidation={doValidation}/>
            <FormPanel/>
        </div>

    )
}