import {store} from "../../store/store";
import {useSelector} from "react-redux";
import {selectLoginStatus, selectToken} from "../validator/authSlice";
import {Grid} from "@mui/material";
import Alert from "../alert/Alert";
import {Strings} from "../../assets/Strings";
import ControlPanel from "../validator/controlPanel/ControlPanel";
import {LoginStatus} from "../validator/loginStatus";
import {useEffect} from "react";
import {_handleValidatorPanelInitialSetup, _handleValidatorPanelPath} from "./functions";
import {selectValidatorNeedUpdate} from "../searchResult/validator/validatorSearchResultSlice";

export default function AuthGate() {
    const status = useSelector(selectLoginStatus)
    const token = useSelector(selectToken)
    const needUpdate = useSelector(selectValidatorNeedUpdate)
    useEffect(() => {
        if (status !== LoginStatus.LOGGED_IN) {
            _handleValidatorPanelPath(store.dispatch, token)
        } else {
            _handleValidatorPanelInitialSetup(store.getState(), store.dispatch)
        }


    }, [status, token, needUpdate])

    return status === LoginStatus.LOGGED_IN ? <ControlPanel></ControlPanel> :
        <Grid container justifyItems={"center"} justifyContent={"center"} alignItems={"center"}
              alignContent={"center"}>
            <Grid item xs={12} md={12} lg={12}>
                <Alert type="info" msg={Strings.it.pleaseLoginAgain}/>
            </Grid>
        </Grid>
}
