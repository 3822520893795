import {configureStore} from '@reduxjs/toolkit';
import infoDialogSlice from '../features/infoDialog/infoDialogSlice';
import navigationSlice from '../features/navigation/navigationSlice';
import drawerSlice from '../features/SideDrawer/drawerSlice';
import apiConnectorIndicatorSlice from '../features/apiConnectionIndicator/apiConnectionIndicatorSlice';
import registerDataMiddleware from '../features/registrationForm/registrationFormMiddleware';
import navigationMiddleware from '../features/navigation/navigationMiddleware';
import RegistrationSlice from '../features/registrationForm/registrationSlice';
import activitiesSlice from '../features/registrationForm/businessDataSection/businessDataSectionSlice'
import informationPanelSlice from '../features/registrationForm/informationPanel/informationPanelSlice';
import richTextFieldSlice from '../features/commonComponents/richTextField/richTextFieldSlice';
import suggestedKeywordsSlice from '../features/registrationForm/DescriptionDataSection/suggestedKeywordsSlice';
import scheduleSectionSlice from '../features/registrationForm/schedulerTab/scheduleSectionSlice';
import branchInformationSlice from '../features/registrationForm/branchInformation/branchInformationSlice';
import schedulerMiddleware from '../features/registrationForm/schedulerTab/schedulerMiddleware';
import keywordContainerMiddleware from '../features/registrationForm/DescriptionDataSection/keywordContainerMiddleware';
import errorStoreSlice from '../features/errorStore/errorStoreSlice';
import validatorSectionSlice from '../features/validator/validatorSectionSlice';
import authSlice from '../features/validator/authSlice';
import healthcareProviderProfileSlice from '../features/healthcareProviderProfile/healthcareProviderProfileSlice';
import alertSlice from '../features/alert/alertSlice';
import fromRegistrationResultSlice from '../features/formRegistrationResult/fromRegistrationResultSlice';
import validatorSearchResultSlice from "../features/searchResult/validator/validatorSearchResultSlice";
import publicSearchResultSlice from "../features/searchResult/public/publicSearchResultSlice";
import validatorSearchResultMiddleware from "../features/searchResult/validator/validatorSearchResultMiddleware";
import publicSearchResultMiddleware from "../features/searchResult/public/publicSearchResultMiddleware";
import privacyDialogSlice from '../features/registrationForm/initialInformationSection/privacyDialogSlice';


export const store = configureStore({
    reducer: {
        navigation: navigationSlice,
        drawer: drawerSlice,
        infoDialog: infoDialogSlice,
        apiConnectionIndicator: apiConnectorIndicatorSlice,
        registrationForm: RegistrationSlice,
        activities: activitiesSlice,
        informationPanel: informationPanelSlice,
        richTextField: richTextFieldSlice,
        suggestedKeywords: suggestedKeywordsSlice,
        scheduleSection: scheduleSectionSlice,
        branchInformation: branchInformationSlice,
        errorStore: errorStoreSlice,
        validatorSection: validatorSectionSlice,
        auth: authSlice,
        healthcareProviderProfile: healthcareProviderProfileSlice,
        alert: alertSlice,
        formRegistrationResult: fromRegistrationResultSlice,
        validatorSearchResult: validatorSearchResultSlice,
        publicSearchResult: publicSearchResultSlice,
        privacyDialog: privacyDialogSlice
    },
    middleware: [
        navigationMiddleware,
        registerDataMiddleware,
        validatorSearchResultMiddleware,
        publicSearchResultMiddleware,
        schedulerMiddleware,
        keywordContainerMiddleware,
    ]
});
