import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import React, { useEffect } from "react";
import {getPublicProfileByCustomId} from "./functions";
import {selectPublicCurrentItem} from "../searchResult/public/publicSearchResultSlice";
import {store} from "../../store/store";
import {setMessage, setProfileIds} from "../formRegistrationResult/fromRegistrationResultSlice";
import {Strings} from "../../assets/Strings";
import FromRegistrationResult from "../formRegistrationResult/FormRegistrationResult";
import {Card, Grid} from "@mui/material";
import {Styles} from "../../design/styles";
import NotFound from "../404/NotFound";
import PersonalDataComponent from "./PersonalDataComponent";
import BranchListComponent from "./BranchListComponent";

export default function HealthcareProviderProfilePublicView() {
    const param = useParams()
    useEffect(()=>{
        getPublicProfileByCustomId(param.id)
    },[param.id])
    const profile = useSelector(selectPublicCurrentItem)
    if (profile && profile.validationStatus && (profile.validationStatus !== "ACCEPTED")) {
        store.dispatch(setProfileIds({uri: profile.custom_id, email: profile.email}))
        store.dispatch(setMessage(Strings.it.waitingForValidation))

        return <FromRegistrationResult/>
    } else if (profile) {
        return (
            <Card
                sx={{
                    backgroundColor: "RGB(85,85,25,.1)",
                    position: "relative",
                    width: "70%",
                    height: "70vh",
                    marginRight: "auto",
                    marginLeft: "auto",
                    marginTop: "16px",
                    borderRadius: "5px",
                    overflowY: "auto",
                    overflowX: "hidden",
                }}
                elevation={1}
            >
                <div
                    style={{backgroundColor: "",}}
                >
                    <Grid
                        {...Styles.FormPanel.SectionHolder.Props}
                        sx={Styles.FormPanel.SectionHolder.Style}
                    >
                        <PublicOverview profile={profile}/>
                    </Grid>
                </div>
            </Card>
        );
    } else {
        return <NotFound/>
    }

}

export function PublicOverview({profile}) {
    return (
        <Grid
            container justifyContent={"center"} alignItems={"center"}
        >
            <Grid sx={{backgroundColor: ""}} item xs={12} md={12}><PersonalDataComponent profile={profile || {}}
                                                                                         editable={false} isValidator={false}/></Grid>
            <Grid sx={{backgroundColor: ""}} item xs={10} md={10}><BranchListComponent branches={profile.Branches || []}
                                                                                       editable={false}/></Grid>
        </Grid>
    )
}
