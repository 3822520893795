import {Chip} from "@mui/material";
import {useDispatch} from "react-redux";
import {removeWordToKeywords} from "../../commonComponents/richTextField/richTextFieldSlice";

const KeywordChip = ({item, removable = false, color,overFlow=true,maxWidth="300px",textColor="black"}) => {
    const dispatch = useDispatch()
    if (removable) {
        return <div>
            <Chip label={item} onDelete={() => dispatch(removeWordToKeywords(item))} sx={{margin: "2px"}}></Chip>
        </div>
    } else {
        return <div>
            <Chip
                label={item}
                sx={{
                    maxWidth: maxWidth,
                    backgroundColor: color,
                    margin:"4px",
                    color:textColor,
                    overflowWrap: overFlow? "break-word":"ellipsis",
                    padding: "2px", borderRadius: "16px"
                }}
            >{item}
            </Chip>
        </div>
    }

}

export default KeywordChip;