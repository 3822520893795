import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {toggleActive} from './infoDialogSlice';
import {store} from "../../store/store";
import {Styles} from "../../design/styles";

export const InfoButton = () => {

    return (
        <IconButton
            {...Styles.InfoButton.IconButton.Props}
            sx={Styles.InfoButton.IconButton}
            onClick={() => {store.dispatch(toggleActive())}}
        >
            <InfoOutlinedIcon/>
        </IconButton>
    );
}