import { useDispatch, useSelector } from "react-redux";
import ActivityComponent from "./ActivityComponent";
import {
    selectAllActivities
} from "./businessDataSectionSlice";
import {selectCurrentSection, setCurrentSection} from "../registrationSlice";
import {registrationSectionEnum} from "../const";
import { Typography } from "@mui/material";

const BusinessDataSection = () => {
    const dispatch = useDispatch()
    const data = useSelector(selectAllActivities)
    const cs = useSelector(selectCurrentSection)
    if (cs!==registrationSectionEnum.BUSINESS_ACTIVITY_SECTION) dispatch(setCurrentSection(registrationSectionEnum.BUSINESS_ACTIVITY_SECTION))
    return (
        <div style={{maxHeight:"40vh",minHeight:"40vh",backgroundColor:"",position:"relative"}}>
        <Typography variant="h6" sx={{marginLeft:5}}>Indica l'/gli ambito/i in cui è attiva la tua organizzazione (max 5 opzioni).</Typography>
            {data.map((item) => <ActivityComponent item={item} key={item.name}/>)}
        </div>
    );
};

export default BusinessDataSection;