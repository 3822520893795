import {Grid} from "@mui/material";
import ValidatorSearchView from "./ValidatorSearchView";
import ValidatorSearchFilter from "./ValidatorSearchFilter";


export default function ValidatorAdvancedSearch(){
    return (
        <Grid container sx={{marginTop: "0"}}>
            <Grid item xs={12} md={12}><ValidatorSearchFilter/></Grid>
            <Grid item xs={12} md={12}><ValidatorSearchView/></Grid>
        </Grid>
    )
}