import { Button } from '@mui/material';
import { Styles } from '../../design/styles';
import { store } from '../../store/store';
import { logo } from '../../util/const';
import { NavigationPath } from '../navigation/navigationPath';
import { navigateTo } from '../navigation/navigationSlice';

export const LogoFlag = () => {
    return (
        <div style={Styles.LogoType.LogoFlag.Container}>
            <Button>
                <img
                    src={logo}
                    alt="logo"
                    style={Styles.LogoType.LogoFlag.Image}
                    onClick={()=>{store.dispatch(navigateTo(NavigationPath.LANDING_PAGE))}}
                />
            </Button>
        </div>
    )
} 