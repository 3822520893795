import React from "react";
import { ListItemText, ListItem } from "@mui/material";
import { useDispatch } from "react-redux";
import { navigateTo } from "../navigation/navigationSlice";
import { close } from "./drawerSlice";
import { NavigationPath } from "../navigation/navigationPath";
import { doLogout } from "../validator/functions";
import theme from "../../design/theme";
import {setApiConnectionStatus} from "../apiConnectionIndicator/apiConnectionIndicatorSlice";
import {ApiConnectionStatus} from "../registrationForm/const";

const SideMenuComponent = ({ item, index }) => {
  let dispatch = useDispatch()
  const handleOnclick = () => {
    if(item.id === NavigationPath.LOG_OUT){
      dispatch(close())
      doLogout()
      dispatch(navigateTo(NavigationPath.VALIDATOR_LOGIN))
      dispatch(setApiConnectionStatus(ApiConnectionStatus.SUCCEED))
    }else{
      dispatch(navigateTo(item.id))
      dispatch(close())
    }
  }
  const registerBtn = [NavigationPath.FORM_REGISTRATION, NavigationPath.VALIDATOR_PANEL].includes(item.id)
  const bkColor = registerBtn ? theme.palette.primary.main : "transparent"
  const color = registerBtn ? "white" : "black"
  return (
    <ListItem button key={index} onClick={handleOnclick}>
      <item.icon />
      <div style={{ width: "10px"}}></div>
      <ListItemText primary={item.name} sx={{color:color,backgroundColor:bkColor,padding:"4px"}}  />
    </ListItem>
  )
}


export default SideMenuComponent
