import {ThemeProvider} from '@emotion/react';
import Layout from './features/layout/Layout';
import theme from './design/theme';
import ApiConnectionIndicator from './features/apiConnectionIndicator/ApiConnectionIndicator';
import {useMediaQuery} from 'react-responsive'
import {useQueriesHooks} from './features/global/mediaQueries';
import {useSelector} from 'react-redux';
import {selectNavigation} from './features/navigation/navigationSlice';
import {useNavigationSystem} from './features/navigation/functions';
import React, {useEffect} from 'react';
import {Strings} from "./assets/Strings";
import {store} from "./store/store";
import {createEmptyAccount, setAccount, setAuthTokens} from "./features/validator/authSlice";


function App() {
    // Catch and freeze the current path as a const before using it in Navigation System
    const currentPath = useSelector(selectNavigation)
    const tooSmall = useQueriesHooks(useMediaQuery).tooSmall

    useNavigationSystem(currentPath);
    //const user = useSelector(selectAccount)

    useEffect(() => {
        // Retrieve the authTokens from the session object.
        let token = sessionStorage.getItem("token")??"";
        let refreshToken = sessionStorage.getItem("refreshToken")??"";
        let account = JSON.parse(sessionStorage.getItem("account"))??createEmptyAccount();

        store.dispatch(setAuthTokens({token: token, refreshToken: refreshToken}))
        store.dispatch(setAccount(account))

    },[currentPath])
    return _renderUI(tooSmall);
}

// UI ELEMENT
function _renderUI(tooSmall) {
    return (
        <ThemeProvider theme={theme}>
            {
                tooSmall
                    ? <div>{Strings.it.toSmallToRender}</div>
                    : <Layout/>
            }
            {/* plug in overlay widgets. */}
            <ApiConnectionIndicator/>
        </ThemeProvider>
    );
}

export default App;


