import { setFilteredWords } from "./suggestedKeywordsSlice";
import {ACTION_UPDATE_SEARCH_QUERY} from "./ActionType";

const keywordContainerMiddleware = (store) => (next) => (action) => {

    if (action.type === ACTION_UPDATE_SEARCH_QUERY) {
        const searchQuery = action.payload
        const words = store.getState().suggestedKeywords.words
        if (searchQuery !== undefined && searchQuery !== "" && searchQuery !== " ") {
            const filteredWords = words.filter(x => x.word.startsWith(searchQuery))
            store.dispatch(setFilteredWords(filteredWords))
        } else { store.dispatch(setFilteredWords(words)) }
    }
    next(action)
}

export default keywordContainerMiddleware;
