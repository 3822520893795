import LandingPageSheet from "./LandingPageSheet"
import React from "react"
import {Grid} from "@mui/material"
import {Styles} from "../../design/styles";


const LandingPage = () => renderUI()


// UI ELEMENT
function renderUI() {
    return (
        <Grid
            {...Styles.LandingPage.Root.Props}
            style={Styles.LandingPage.Root.Style}
        >
            <Grid item xs={12}
                  md={12}>
                <Grid container><Grid item xs={12} md={12}>
                    <LandingPageSheet/>
                </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default LandingPage