import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { selectWordToKeyword } from "../../commonComponents/richTextField/richTextFieldSlice";
import KeywordChip from "./KeywordChip";
import {Box} from "@mui/system";

const KeywordsContainer = () => {
    let keywords = useSelector(selectWordToKeyword)
    return <div style={{}}>
        <Box
            sx={{
                marginTop:"8px",
                display: 'flex',
                flexWrap: 'wrap',
                listStyle: 'none',
                backgroundColor:""
            }}
            component="ul"
        >
            <Grid container sx={{overflow:"auto"}} >
                {keywords.map((item, index) => { return <KeywordChip item={item} key={index} removable={true} /> })}
            </Grid>
        </Box>
    </div>
}

export default KeywordsContainer;