import React from "react";
import { store } from "../../store/store";
import SideMenuComponent from "./SideMenuComponent";


const MenuList = ({items})=>{
    const account = store.getState().auth.account

    return _renderUI(items, account)
}

// UI ELEMENT
function _renderUI(items, account) {
    return (
        <div>
            {[...items].slice(0, -1).map((item, index) => {
                    return <SideMenuComponent key={index} item={item} index={index}/>
                }
            )}
            {account !== null && account.id > 0 ?
                <SideMenuComponent key={items.length} item={items[items.length - 1]} index={items.length}/> : <></>}
        </div>
    );
}


export default MenuList
