import {Grid, IconButton, TextField, Typography} from "@mui/material"

import AddIcon from '@mui/icons-material/Add';
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addWordToKeywords, selectWordToKeyword} from "./richTextFieldSlice";
import {selectInputDescription, setDescription} from "../../registrationForm/registrationSlice";
import {Strings} from "../../../assets/Strings";

const RichTextField = ({}) => {
    const dispatch = useDispatch()
    const description = useSelector(selectInputDescription)
    const selectedWords = useSelector(selectWordToKeyword)
    const [word, setWord] = useState("")

    const handleChange = (e) => {
        dispatch(setDescription(e.target.value))
    }
    return (
        <div style={{position: "relative",backgroundColor:"",margin:""}}>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <TextField
                        label="Descrivi la tua organizzazione"
                        multiline
                        inputProps={{maxLength: 300}}
                        fullWidth
                        rows={4}
                        value={description}
                        onChange={handleChange}
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Grid container justifyContent={"center"} alignContent={"center"} justifyItems={"center"}>
                        <Grid item xs={12} md={6}><Typography sx={{marginTop:"22px"}}>{Strings.it.addYourKeyword}</Typography></Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={"aggiungi..."}
                                aria-label={"aggiungi una parola chiave"}
                                disabled={selectedWords.length > 5}
                                sx={{maxWidth: "150px", maxHeight: "140px", marginTop: "8px", right: 0}}
                                onChange={(e) => {
                                    setWord(e.target.value)
                                }}
                                InputProps={{
                                    endAdornment: <IconButton variant={"primary"} disabled={word.length < 2}
                                                              onClick={() => {
                                                                  if (word.length > 2 && selectedWords.length < 6) {
                                                                      dispatch(addWordToKeywords(word))
                                                                  } else setWord("")
                                                              }}>
                                        <AddIcon/>
                                    </IconButton>
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>

    )
}

export default RichTextField;