import {Box} from "@mui/system";
import {Typography} from "@mui/material";

import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {useState} from "react";
import {store} from "../../../store/store";
import {
    selectDoConsent,
    setDoConsent,
} from "../registrationSlice";
import {consentItems} from "./consentItems";
import {useSelector} from "react-redux";

export default function ConsentComponent({item,editable}) {
    return <Box>
        <Typography variant={"h6"}>{item.title}</Typography>
        <Typography variant={"p"}>{item.description}</Typography>
        <br/>
        <RowRadioButtonsGroup item={item} editable={editable}/>
    </Box>
}

function RowRadioButtonsGroup({item,editable}) {
    const doConsent = useSelector(selectDoConsent)
    const initialValue = doConsent
    const [value,setValue] = useState(initialValue)
    const handleOnChangeCompose = (_,selected) =>{
        if (editable){
            if (item.id === consentItems[0].id){
                store.dispatch(setDoConsent(selected))
                setValue(selected)
            }
        }
    }
    return (
        <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={value}
                onChange={handleOnChangeCompose}
            >
                {item.actions.map((action) => {
                    return <FormControlLabel
                        key={item.id+action}
                        value={action}
                        control={<Radio/>}
                        label={action}
                    />
                })}

            </RadioGroup>
        </FormControl>
    );
}