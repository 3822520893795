import {Route, Routes} from 'react-router-dom';
import NotFound from "../404/NotFound";
import {Styles} from "../../design/styles";
import {AcceptablePaths} from "../../assets/AcceptablePaths";
import LandingPage from "../landingPage/LandingPage";
import LoginSection from "../validator/loginSection/LoginComponent";
import FormRegistrationResult from "../formRegistrationResult/FormRegistrationResult";
import FormPanel from "../registrationForm/FormPanel";
import HealthcareProviderProfilePublicView from "../healthcareProviderProfile/HealthcareProviderProfilePublicView";
import AuthGate from "../gate/AuthGate";
import HealthcareProviderProfileValidatorView
    from "../healthcareProviderProfile/HealthcareProviderProfileValidatorView";
import EditableHealthcareProviderProfile from "../editableHealthcareProviderProfile/EditableHealthcareProviderProfile";
import ContentSheet from "../contentSheet/ContentSheet";
import {Strings} from "../../assets/Strings";
import {PublicAdvancedSearch} from "../searchResult/public/PublicAdvancedSearch";
import PersonalDataComponent from '../healthcareProviderProfile/PersonalDataComponent';

export default function Scaffold() {
    return (
        <div style={Styles.Scaffold.Root}>
            <Routes>
                <Route exact path={AcceptablePaths.ROOT}
                       element={<LandingPage/>}>
                </Route>
                <Route path={AcceptablePaths.LOGIN}
                       element={<LoginSection/>}>
                </Route>

                <Route path={AcceptablePaths.REGISTER_RESULT}
                       element={<FormRegistrationResult/>}>
                </Route>
                <Route path={AcceptablePaths.REGISTER_PROFILE}
                       element={<FormPanel/>}>
                </Route>
                <Route path={AcceptablePaths.PUBLIC_PROFILE_SEARCH}
                       element={<PublicAdvancedSearch/>}>

                </Route>
                <Route path={AcceptablePaths.PUBLIC_PROFILE_VIEW}
                       element={<HealthcareProviderProfilePublicView/>}>
                </Route>
                <Route path={AcceptablePaths.VALIDATOR_PANEL}
                       element={<AuthGate/>}>
                </Route>
                <Route path={AcceptablePaths.VALIDATOR_PROFILE_VIEW}
                       element={<HealthcareProviderProfileValidatorView/>}>

                </Route>
                <Route path={AcceptablePaths.VALIDATOR_EDIT_PROFILE}
                       element={<EditableHealthcareProviderProfile/>}>
                </Route>

                <Route path={"/dev"}
                       element={<FormPanel/>}>
                </Route>
                <Route path={AcceptablePaths.OBIETTIVO}
                       element={<ContentSheet header={Strings.it.ourGoal}
                                              content={Strings.it.ourGoalBody}/>}>
                </Route>
                <Route path={AcceptablePaths.CONTACT}
                       element={<ContentSheet header={Strings.it.contacts}
                                              content={Strings.it.noInformationProvided}/>}>
                </Route>
                <Route path={AcceptablePaths.ABOUT}
                       element={<ContentSheet header={Strings.it.whoWeAre}
                                              content={Strings.it.whoWeAreBody}/>}>
                </Route>
                <Route path={AcceptablePaths.SUPPORT}
                       element={<ContentSheet header={Strings.it.contacts}
                                              content={Strings.it.noInformationProvided}/>}>
                </Route>
                <Route path={window.location.pathname}
                       element={<NotFound/>}>
                </Route>
            </Routes>
        </div>
    )
}
