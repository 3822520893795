import {Card, Grid} from "@mui/material"
import KeywordChip from "../registrationForm/DescriptionDataSection/KeywordChip";

export default function ActivityDetailsComponent({profile}) {
    const activities = profile.activities ? profile.activities  : [{name: "sdfasd asdfasdfa asdfad fa asdfasdfasdfa"}, {name: "sdfasd asdfasdfa asdfad fa asdfasdfasdfa"}, {name: "sdfasd asdfasdfa asdfad fa asdfasdfasdfa sdfasd asdfasdfa asdfad fa asdfasdfasdfa"}, {name: "sdfa"}, {name: "sdfa"}, {name: "sdfa"}, {name: "sdfa"}, {name: "sdfa"},]
    const keywords = profile.keywords ? profile.keywords.concat(profile.keywords) : []
    const uniqueKeys = [...(new Set(keywords.map((w) => {
        return w.word
    })))]
    const composedKeys = uniqueKeys.concat(activities.map((i) => {
        return i.name
    }))
    return (
        <Card sx={{
            position: "",
            backgroundColor: "RGB(223,230,230,.2)",
            padding: "4px",
            borderRadius: "8px",
            margin: "16px",
            overflow:"auto"
        }}>
            <Grid container justifyContent={"center"} alignItems={"center"}>{composedKeys.map((item, index) => {
                return <KeywordChip item={item} removable={false} color={"RGB(212,212,223,1)"}/>
            })}</Grid>
        </Card>
    )
}