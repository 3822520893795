import { addNewBranch, setActiveBranchIndex } from "../branchInformation/branchInformationSlice";
import { createNewBranch } from "../branchInformation/functions";
import {ACTION_ADD_NEW_TAB, ACTION_SET_ACTIVE_TAB} from "./ActionType";

const schedulerMiddleware = (store) => (next) => (action) => {


    if (action.type === ACTION_ADD_NEW_TAB){
        store.dispatch(addNewBranch(createNewBranch()))
    }


    if(action.type === ACTION_SET_ACTIVE_TAB){
        store.dispatch(setActiveBranchIndex(action.payload))
    }
    next(action);
}


export default schedulerMiddleware;


