import { createSlice } from "@reduxjs/toolkit";

const fromRegistrationResultSlice = createSlice({
    name: 'formRegistrationResult',
    initialState: { message: "",profileIds:{uri:null,email:null} },
    reducers: {
        setMessage: (state,action) => {
            state.message = action.payload
        },
        setProfileIds: (state,action) => {
            state.profileIds = action.payload
        }
    }
})

export default fromRegistrationResultSlice.reducer
export const selectMessage = (state) => state.formRegistrationResult.message;
export const selectProfileIds = (state) => state.formRegistrationResult.profileIds;
export const { setMessage,setProfileIds } = fromRegistrationResultSlice.actions