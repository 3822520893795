import moment from "moment";
import {Strings} from "../../../assets/Strings";

export function createNewBranch() {
    return {
        name: "",
        address: "",
        schedule: createSchedule(),
    };
}

export function createEmptyDay(dayName) {
    const currentTime = momentObjectToHours(moment("0000", "HHmm"));
    return {
        dayName: dayName,
        openingTimeMorning: currentTime,
        openingTimeEvening: currentTime,
        closingTimeMorning: currentTime,
        closingTimeEvening: currentTime,
        morningClose:false,
        eveningClose:false,
        isClosed: false
    }
}

export function createSchedule() {
    const currentTime = momentObjectToHours(moment("0000", "HHmm"));
    return {
        days: Strings.it.days.map((day) => {
            return {
                dayName: day,
                openingTimeMorning: currentTime,
                openingTimeEvening: currentTime,
                closingTimeMorning: currentTime,
                closingTimeEvening: currentTime,
                morningClose:false,
                eveningClose:false,
                isClosed: false
            }
        })
    }
}

export function createDay(initDay) {
    try {
        return {
            dayName: initDay.dayName,
            openingTimeEvening: momentObjectToHours(initDay.openingTimeEvening),
            openingTimeMorning: momentObjectToHours(initDay.openingTimeMorning),
            closingTimeMorning: momentObjectToHours(initDay.closingTimeMorning),
            closingTimeEvening: momentObjectToHours(initDay.closingTimeEvening),
            morningClose:initDay.morningClose ? initDay.morningClose : false,
            eveningClose:initDay.eveningClose ? initDay.eveningClose : false,
            isClosed: initDay.isClosed ? initDay.isClosed : false
        }
    } catch (error) {
        try {
            return {
                dayName: initDay.dayName,
                openingTimeEvening: hoursToMomentObject(initDay.openingTimeEvening),
                openingTimeMorning: hoursToMomentObject(initDay.openingTimeMorning),
                closingTimeMorning: hoursToMomentObject(initDay.closingTimeMorning),
                closingTimeEvening: hoursToMomentObject(initDay.closingTimeEvening),
                morningClose:initDay.morningClose ? initDay.morningClose : false,
                eveningClose:initDay.eveningClose ? initDay.eveningClose : false,
                isClosed: initDay.isClosed ? initDay : false
            }
        } catch (error) {
            return {
                dayName: initDay.dayName,
                openingTimeEvening: (initDay.openingTimeEvening),
                openingTimeMorning: (initDay.openingTimeMorning),
                closingTimeMorning: (initDay.closingTimeMorning),
                closingTimeEvening: (initDay.closingTimeEvening),
                morningClose:initDay.morningClose ? initDay.morningClose : false,
                eveningClose:initDay.eveningClose ? initDay.eveningClose : false,
                isClosed: initDay.isClosed ? initDay : false
            }
        }

    }
}

export function hoursToMomentObject(HHmm) {
    return moment(HHmm, 'HH:mm a')
}

export function momentObjectToHours(momentObject) {
    return momentObject.format("HH:mm")
}

export function updateBranchInformationByValue(branch) {
    return {
        name: branch.name,
        address: branch.address,
        schedule: {
            days: branch.schedule.days.map((d, index) => {
                return {
                    dayName: d.dayName,
                    openingTimeMorning: (d.openingTimeMorning),
                    openingTimeEvening: (d.openingTimeEvening),
                    closingTimeMorning: (d.closingTimeMorning),
                    closingTimeEvening: (d.closingTimeEvening),
                    morningClose:d.morningClose ? d.morningClose : false,
                    eveningClose:d.eveningClose ? d.eveningClose : false,
                    isClosed: d.isClosed
                }
            })
        }
    }
}