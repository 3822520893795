import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { store } from "../../store/store";
import { close } from "../SideDrawer/drawerSlice";
import { NavigationPath } from "./navigationPath";
import { navigateTo } from "./navigationSlice";
import { close as infoClose } from "../infoDialog/infoDialogSlice";
import {useNavigateBackInterceptor} from "../global/useNavigateBackInterceptor";

// Navigation path and url path are decorative and will be updated by the states and "NOT VICE VERSA";
// However the front-end app will react to them to make the experience consistence.


// Hook: navigation system wrapper hook
export function useNavigationSystem(currentPath) {
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => { navigateToRoute(navigate, store); }, [currentPath, navigate]);
  useNavigateBackInterceptor(() => { updateNavigationPath(location.pathname); });
}


// React to change pathname on browser's url box.
export function updateNavigationPath(pathname) {
  switch (pathname) {
    case "/":
      store.dispatch(navigateTo(NavigationPath.LANDING_PAGE))
      break;
    case "/login":
      store.dispatch(navigateTo(NavigationPath.VALIDATOR_LOGIN))
      break;
    case "/logout":
      store.dispatch(navigateTo(NavigationPath.VALIDATOR_LOGIN))
      break;
    case "/census/register":
      store.dispatch(navigateTo(NavigationPath.FORM_REGISTRATION))
      break;
    case "/obiettivo":
      store.dispatch(navigateTo(NavigationPath.OBIETTIVO))
      break;
    case "/news":
      store.dispatch(navigateTo(NavigationPath.NEWS))
      break;
    case "/contact":
      store.dispatch(navigateTo(NavigationPath.CONTACT))
      break;
    case "/about":
      store.dispatch(navigateTo(NavigationPath.ABOUT))
      break;
    case "/validator/panel":
      store.dispatch(navigateTo(NavigationPath.VALIDATOR_PANEL))
      break;
    case "/census/profiles/search":
      store.dispatch(navigateTo(NavigationPath.ADVANCED_SEARCH))
      break;
    case "/census/profiles/:id":
      store.dispatch(navigateTo(NavigationPath.REGISTERED_ACTIVITY))
      break;
    case "/census/register/result":
      store.dispatch(navigateTo(NavigationPath.FORM_REGISTRATION_RESULT))
      break;
    case "/validator/panel/profiles/edit/:id":
      store.dispatch(navigateTo(NavigationPath.EDIT_PROFILE))
      break;
    case "/validator/panel/profiles/:id":
      store.dispatch(navigateTo(NavigationPath.REGISTERED_ACTIVITY_VALIDATOR_VIEW))
      break;
    case "/support":
      store.dispatch(navigateTo(NavigationPath.SUPPORT))
      break;
    default:
      store.dispatch(navigateTo(NavigationPath.NOT_FOUND))
      break;
  }
}

// Update browser's url box based on the internal navigation path
export function navigateToRoute(navigate) {
  const currentRequestNavigationPath = store.getState().navigation.value
  switch (currentRequestNavigationPath) {
    case NavigationPath.EDIT_PROFILE:
      try {
        const uri = store.getState().validatorSection.currentEntryToExpand.custom_id
        navigate(`/validator/panel/profiles/edit/${uri}`)
      } catch (e) {
        navigate("ErrorOnServer")
      }
      break;
    case NavigationPath.LANDING_PAGE:
      navigate("/")
      break;
    case NavigationPath.FORM_REGISTRATION:
      navigate("/census/register")
      break;
    case NavigationPath.OBIETTIVO:
      navigate("/obiettivo")
      break;
    case NavigationPath.CONTACT:
      navigate("/contact")
      break;
    case NavigationPath.ABOUT:
      navigate("/about")
      break;
    case NavigationPath.ADVANCED_SEARCH:
      navigate("/census/profiles/search")
      break;
    case NavigationPath.VALIDATOR_LOGIN:
      navigate("/login")
      break;
    case NavigationPath.VALIDATOR_PANEL:
      navigate("/validator/panel")
      break;
    case NavigationPath.FORM_REGISTRATION_RESULT:
      navigate("/census/register/result")
      break;
    case NavigationPath.REGISTERED_ACTIVITY:
      try {
        const uri = store.getState().publicSearchResult.publicCurrentItem.custom_id
        navigate(`/census/profiles/${uri}`)
      } catch (error) {
        navigate("ErrorOnServer")
      }
      break;
    case NavigationPath.REGISTERED_ACTIVITY_VALIDATOR_VIEW:
      try {
        const uri = store.getState().validatorSection.currentEntryToExpand.custom_id
        navigate(`/validator/panel/profiles/${uri}`)
      } catch (error) {
        navigate("ErrorOnServer")
      }
      break;
    case NavigationPath.SUPPORT:
      navigate("/support")
      break;
    default:
      navigate(window.location.pathname)
      break;
  }
}


export function closeAllModals(store) {
  store.dispatch(infoClose())
  store.dispatch(close())
}
