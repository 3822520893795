import {NETWORK} from "../../networkEngine/NETWORK"
import {store} from "../../store/store";
import {NavigationPath} from "../navigation/navigationPath";
import {navigateTo, navigateToError} from "../navigation/navigationSlice";
import {createEmptyAccount, logout, setAccount, setAuthTokens, setLoginStatus} from "./authSlice";
import {setLoginError} from "./validatorSectionSlice";
import {setApiConnectionStatus} from "../apiConnectionIndicator/apiConnectionIndicatorSlice";
import {ApiConnectionStatus} from "../registrationForm/const";
import {setValidatorData} from "../searchResult/validator/validatorSearchResultSlice";
import {LoginStatus} from "./loginStatus";


export function doLogin(user) {
    NETWORK
        .doLogin(user).then((result) => {
        if (result.status === 200) {
            store.dispatch(setAuthTokens({
                token: result.data.access,
                refreshToken: result.data.refreshToken,
            }));
            let user = result.data.user
            store.dispatch(setAccount({username:user.username,email:user.email,id:user.id}));
            store.dispatch(setLoginStatus(LoginStatus.LOGGED_IN));
            store.dispatch(navigateTo(NavigationPath.VALIDATOR_PANEL));
        }
        handleLoginError(result.status)
    }).catch((err) => {
        store.dispatch(setLoginStatus(LoginStatus.FAILED))
        store.dispatch(setValidatorData([]));
        handleLoginError(err)
        store.dispatch(navigateToError({
            error: "error on validator login",
            title: "error on validator login",
            details: "Cannot identify the user."
        }))
    });
    store.dispatch(setApiConnectionStatus(ApiConnectionStatus.SUCCEED))
}

export function doLogout() {
    store.dispatch(setValidatorData([]));
    store.dispatch(logout());
    store.dispatch(setApiConnectionStatus(ApiConnectionStatus.SUCCEED))
    store.dispatch(setLoginStatus(LoginStatus.LOG_OUT))
}

export function verifyToken(token, onSucceed, onFailed) {
    const sessionStoredToken = sessionStorage.getItem("token")
    if (!token || token === 'not_a_token') token = sessionStoredToken ? sessionStoredToken : "not_a_token"
    NETWORK
        .verifyToken(token)
        .then((response) => {
            if (response.status === 200) {
                if (onSucceed) onSucceed(response)
            } else {
                if (onFailed) {
                    store.dispatch(setAuthTokens({token:"",refreshToken:""}))
                    store.dispatch(setAccount(createEmptyAccount()))
                    onFailed(response);
                }
            }
        })
        .catch((e) => {
            store.dispatch(setAccount(createEmptyAccount()))
            if (onFailed) onFailed(e);
        })
}

function handleLoginError(statusCode) {
    switch (statusCode) {
        case 200:
            store.dispatch(setLoginError({error: "", title: "", details: ""}))
            break;
        default:
            store.dispatch(setLoginError({
                error: "error on validator login",
                title: "error on validator login",
                details: "details on the error on validator login"
            }))
            break;
    }
}