import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../commonComponents/inputField/InputField";
import { selectActiveBranchData, updateBranchInformation } from "./branchInformationSlice";
import Week from "./Week";

const BranchInformation = ({ value, index }) => {
    const branch = {...useSelector(selectActiveBranchData)}
    const dispatch = useDispatch()
    const handleOnInputChange = (fieldType,value) => { 
        if(fieldType==="ADDRESS") branch.address = value;
        if(fieldType==="SCHEDULE") branch.schedule = value
        
        dispatch(updateBranchInformation(branch)) 
    }
    return (
        <div hidden={value !== index} value={value} index={index}>
        <div style={{margin:"8px"}}>
            <InputField label="Indirizzo" fieldType="ADDRESS" onValueChange={handleOnInputChange} initialValue={branch.address} section={"BRANCH_DATA_REQUIRED"} />
        </div>
            <Typography variant="body2" sx={{marginLeft:3, marginBottom:3}}>Indica giorni e orari in cui è possibile contattare o incontrare la tua organizzazione.</Typography>
            <Week fieldType="SCHEDULE" onScheduleChange={handleOnInputChange} initialValue={branch.schedule}/>
        </div>
    )
}

export default BranchInformation;