import {ACTION_NEED_UPDATE, ACTION_SEARCH_GET_VALIDATOR_DATA} from "./ActionType";
import {_handleValidatorPanelInitialSetup} from "../../gate/functions";
import {searchValidatorProfiles} from "../functions";

const validatorSearchResultMiddleware = (store) => (next) => (action) => {

    if (action.type === ACTION_SEARCH_GET_VALIDATOR_DATA) {
        searchValidatorProfiles(store.getState().auth.token,store.getState().validatorSearchResult.searchParams,action.payload)
    }
    if (action.type === ACTION_NEED_UPDATE && action.payload===true){
        _handleValidatorPanelInitialSetup(store.getState(),store.dispatch)
    }
    next(action);
}


export default validatorSearchResultMiddleware;




