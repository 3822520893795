import { NETWORK } from "../../networkEngine/NETWORK"
import { store } from "../../store/store"
import {setCurrentEntryToExpand, setIsOnModificationSection} from "../validator/validatorSectionSlice"
import {setPublicCurrentItem} from "../searchResult/public/publicSearchResultSlice";
import {verifyToken} from "../validator/functions";
import {navigateTo} from "../navigation/navigationSlice";
import {NavigationPath} from "../navigation/navigationPath";
import {handleProfileEditRequest} from "../registrationForm/functions";
import {cleanForm} from "../registrationForm/registrationSlice";
import {resetTabs, setActiveTab} from "../registrationForm/schedulerTab/scheduleSectionSlice";
import {setActiveBranchIndex} from "../registrationForm/branchInformation/branchInformationSlice";

export function getPublicProfileByCustomId(customId) {
    NETWORK
        .getProfileByCustomId(customId)
        .then((response) => {
            if (response.data[0] !== null || response.data[0] !== undefined){
                store.dispatch(setPublicCurrentItem(response.data[0]))
            }else{store.dispatch(setPublicCurrentItem({}))}
        }).catch((e)=>{})
}

export function getValidatorProfileByCustomId(token,customId) {

    verifyToken(token,
        ()=>{
            NETWORK
                .getProfileByCustomId(customId)
                .then((response) => {
                    if (response.data[0] !== null || response.data[0] !== undefined){
                        store.dispatch(cleanForm())
                        store.dispatch(resetTabs())
                        store.dispatch(setActiveTab(0))
                        store.dispatch(setActiveBranchIndex(0))
                        store.dispatch(setIsOnModificationSection(true))
                        store.dispatch(setCurrentEntryToExpand(response.data[0]))
                        handleProfileEditRequest(response.data[0])
                    }else{store.dispatch(setCurrentEntryToExpand(null))}
                }).catch((e)=>{store.dispatch(setCurrentEntryToExpand(null))})
        },
        ()=>{
        store.dispatch(navigateTo(NavigationPath.VALIDATOR_LOGIN))
        })
}
