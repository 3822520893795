import axios from "axios"
import { authorizedClient, publicClient } from "./axios";
import { API } from './api';

axios.defaults.withCredentials = true;

export const NETWORK = {
    doLogin: async (data) => authorizedClient.post(API.loginValidator, data),
    verifyToken: async (token) => publicClient.post(API.VERIFY_TOKEN, { token: token }),

    getAllActivities: async (pageUrl) => publicClient.get(pageUrl),
    getAllActivitiesWithoutPagination: async () => publicClient.get(API.allActivities),
    getAllKeyWords: async (pageUrl) => publicClient.get(pageUrl),

    checkProfileExist: async (operativeEmail) => publicClient.get(API.CHECK_URL, { params: { operativeEmail: operativeEmail } }),
    getProfileByCustomId: async (custom_id) => publicClient.get(API.PROFILE_BY_URI, { params: { custom_id: custom_id } }),

    publicAll: async (pageUrl,searchParams) => publicClient.get(pageUrl,{params:{...searchParams}}),
    validatorAll: async (pageUrl,searchParams,config) => authorizedClient.get(pageUrl,{...config, params:{...searchParams}}),

    updateRegisteredForm: async (data, config) => authorizedClient.post(API.updateRegisteredForm, data, config),
    deleteProfile: async (operativeEmail,config) => authorizedClient.get(API.DELETE_PROFILE,{...config, params: { operativeEmail: operativeEmail }}),
    postHealthcareWorkerData: async (data, config) =>  publicClient.post(API.registerActivity, data),
}