import { createSlice } from "@reduxjs/toolkit";

const informationPanelSlice = createSlice({
    name: "informationPanel",

    initialState: {
        sectionInformation: "Seleziona una sezione per mostrare più informazioni",
        fieldInformation: "Clicca sull'icona di info per avere più dettagli su un campo",
        errorInformation:{type:"",title:"",body:""}
    },
    reducers: {
        setSectionInformation: (state, action) => {
            state.sectionInformation = action.payload;
        },
        setFieldInformation: (state, action) => {
            state.fieldInformation = action.payload;
        },
        setErrorInformation: (state,action) => {
            state.errorInformation = action.payload
        }
    }
});

export const selectSectionInformation = (state) => state.informationPanel.sectionInformation;
export const selectFieldInformation = (state) => state.informationPanel.fieldInformation;
export const selectErrorInformation = (state) => state.informationPanel.errorInformation;

export const {
    setSectionInformation,
    setFieldInformation,
    setErrorInformation
} = informationPanelSlice.actions;

export default informationPanelSlice.reducer;
