import { Checkbox, Divider, FormControl, FormControlLabel, Grid } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { addBusinessActivity, removeBusinessActivity, selectInputBusinessActivity } from "../registrationSlice";


const ActivityComponent = ({ item,key }) => {
    const activities = useSelector(selectInputBusinessActivity)
    const dispatch = useDispatch()
    const selectActivity = (e) => {
        if (e.target.checked) {
            dispatch(addBusinessActivity(item))
        } else {
            dispatch(removeBusinessActivity(item))
        }
    }
    const isSelected = () => {
        return activities.map((i)=>{return i.name}).includes(item.name)
    }
    const shouldBeDisabled = () => {
        if (isSelected()) return false
        if (activities.length > 4) return true
    }
    return (
        <Fragment>
            <Box sx={{ margin: "1rem 2rem 0 2rem", overflow: "auto", position: "relative" }}>
                <Divider />
                <Grid container sx={{ padding: ".5rem 0 0 0" }}>
                    <Grid item>
                        <FormControl>
                            <FormControlLabel
                                sx={{ marginLeft: "1rem" }}
                                value="bottom"
                                control={
                                    <Checkbox
                                        disabled={shouldBeDisabled()}
                                        defaultChecked={isSelected()}
                                        onChange={(e) => { selectActivity(e) }}
                                    />
                                }
                                label={item.name}
                                labelPlacement="end"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Fragment>
    )
}

export default ActivityComponent