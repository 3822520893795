import {
    setApiConnectionStatus,
    setApiConnectionStatusDetails
} from "../features/apiConnectionIndicator/apiConnectionIndicatorSlice"
import {NavigationPath} from "../features/navigation/navigationPath"
import {navigateTo} from "../features/navigation/navigationSlice"
import {ApiConnectionStatus} from "../features/registrationForm/const"
import {getHealthcareWorkerEmptyObject} from "../features/registrationForm/functions"
import {cleanForm} from "../features/registrationForm/registrationSlice"
import {logout} from "../features/validator/authSlice"
import {setCurrentEntryToExpand} from "../features/validator/validatorSectionSlice"
import {store} from "../store/store"
import {NETWORK} from "./NETWORK"
import {setMessage, setProfileIds} from "../features/formRegistrationResult/fromRegistrationResultSlice"
import {setErrorInformation} from "../features/registrationForm/informationPanel/informationPanelSlice"
import {validationStatus} from "../util/const";


export async function isEmailAlreadyRegistered(personalData) {
        let isAlreadyExist = null
        let ce = store.getState().validatorSection.currentEntryToExpand
        await NETWORK
            .checkProfileExist(personalData.operativeEmail)
            .then((response) => {
                if (response.status === 200) {
                    isAlreadyExist = response.data.length >= 1
                    if (ce && ce.custom_id){
                        isAlreadyExist = response.data[0].custom_id !== ce.custom_id
                    }
                    store.dispatch(setApiConnectionStatus(ApiConnectionStatus.SUCCEED))
                } else {
                    store.dispatch(setApiConnectionStatus(ApiConnectionStatus.SUCCEED));
                }
            }).catch(() => {
                store.dispatch(setApiConnectionStatus(ApiConnectionStatus.FAILED))
                store.dispatch(setApiConnectionStatusDetails("There are some issues connecting to the server."))
            })
        return isAlreadyExist
}

export function requestValidation(item, validationStatus) {
    store.dispatch(setApiConnectionStatus(ApiConnectionStatus.LOADING))
    let validatedItem = {...item, validationStatus: validationStatus}
    const token = store.getState().auth.token
    const config = {
        headers: {Authorization: `Bearer ${token}`}
    };
    NETWORK
        .updateRegisteredForm(validatedItem, config)
        .then(() => {
            store.dispatch(setCurrentEntryToExpand(getHealthcareWorkerEmptyObject()))
            store.dispatch(setApiConnectionStatus(ApiConnectionStatus.SUCCEED))
        }).catch(() => {
        store.dispatch(setApiConnectionStatus(ApiConnectionStatus.FAILED))
    })
    store.dispatch(navigateTo(NavigationPath.VALIDATOR_PANEL))
}


export function requestDeletion(email) {
    const token = store.getState().auth.token
    const config = {
        headers: {Authorization: `Bearer ${token}`}
    };
    NETWORK
        .deleteProfile(email, config)
        .then(() => {
            store.dispatch(setCurrentEntryToExpand(getHealthcareWorkerEmptyObject()))
            store.dispatch(navigateTo(NavigationPath.VALIDATOR_PANEL))
        }).catch(() => {
    })
}


export function handleRegisterHealthcareWorkerData() {
    let data = store.getState().registrationForm.healthcareWorkerData
    data = { ...data, custom_id: "-",validationStatus:validationStatus.TO_VALIDATED}
    NETWORK
        .postHealthcareWorkerData(data)
        .then((response) => {
            if (response.status === 201) {
                store.dispatch(setApiConnectionStatus(ApiConnectionStatus.SUCCEED))
                store.dispatch(cleanForm())
                store.dispatch(setProfileIds({uri: response.data.custom_id, email: response.data.email}))
                store.dispatch(setMessage("La tua richiesta é stata ricevuta."))
                store.dispatch(navigateTo(NavigationPath.FORM_REGISTRATION_RESULT))
            } else {
                store.dispatch(setApiConnectionStatus(ApiConnectionStatus.FAILED))
                store.dispatch(setApiConnectionStatusDetails("There are some issues connecting to the server."))
            }
        }).catch(() => {
        store.dispatch(logout())
    })
}


export function handleUpdateHealthcareWorkerData() {
    const token = store.getState().auth.token
    let data = store.getState().registrationForm.healthcareWorkerData
    const validationStatus = store.getState().registrationForm.validationStatus
    data = {...data,validationStatus:validationStatus}
    const config = {headers: {Authorization: `Bearer ${token}`}};
    NETWORK
        .updateRegisteredForm(data, config)
        .then((response) => {
            if (response.status === 201) {
                store.dispatch(setApiConnectionStatus(ApiConnectionStatus.SUCCEED))
                store.dispatch(cleanForm())
                store.dispatch(setProfileIds({uri: response.data.custom_id, email: response.data.email}))
                store.dispatch(setMessage("Il profilo è stato aggiornato."))
                store.dispatch(navigateTo(NavigationPath.FORM_REGISTRATION_RESULT))
            } else {
                store.dispatch(setApiConnectionStatus(ApiConnectionStatus.FAILED))
                store.dispatch(setApiConnectionStatusDetails("There are some issues connecting to the server."))
            }
        }).catch(() => {
        store.dispatch(setApiConnectionStatus(ApiConnectionStatus.FAILED))
        store.dispatch(setApiConnectionStatusDetails("There are some issues connecting to the server."))
    })
}