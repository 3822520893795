import * as React from "react";
import {store} from "../../../store/store";
import {navigateTo} from "../../navigation/navigationSlice";
import {Container, Grid, Popover} from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import KeywordChip from "../../registrationForm/DescriptionDataSection/KeywordChip";
import {CHIP_COLORS} from "../../../design/colors";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import {NavigationPath} from "../../navigation/navigationPath";
import {setPublicCurrentItem} from "./publicSearchResultSlice";


export default function PublicRegisteredProfile({item}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = store.dispatch
    let words = item.keywords.map((w) => {
        return w.word
    })
    let acta = item.activities.map((w) => {return w.name})
    words = acta.concat(words)
    const onDetailsClick = () => {
        dispatch(setPublicCurrentItem(item))
        dispatch(navigateTo(NavigationPath.REGISTERED_ACTIVITY))
    }

    const onShareClick = (e) => {
        navigator.clipboard.writeText(`https://${window.location.hostname}:${window.location.port}/census/profiles/${item.custom_id}`);
        if (anchorEl !== null) {
            setAnchorEl(null);
        } else {
            setAnchorEl(e.currentTarget);
        }
    }

    return (<Grid item sx={{padding: "5px"}}>
        <Container>
            <Card sx={{
                maxWidth: 320, minWidth: 320, maxHeight: 320, minHeight: 320, position: "relative",
                backgroundColor: "RGB(222,222,202,.1)"
            }}>
                <Grid container spacing={1} direction={"column"} justifyContent={"center"}
                      justifyItems={"center"} alignItems={"center"}>

                    <Grid item sx={{marginTop: "4px", borderRadius: "8px"}}>
                        <div style={{backgroundColor: "RGB(221,191,221,.1)", padding: "4px", borderRadius: "4px"}}>
                            <Typography gutterBottom component={"p"} variant="body2" noWrap={false}
                                        textOverflow={"ellipsis"}
                                        sx={{
                                            display: '-webkit-box',
                                            textOverflow: "ellipsis",
                                            overflow: 'hidden',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 2,
                                            fontWeight: "bold", maxWidth: "300px"
                                        }}>
                                {item.title}
                            </Typography>
                        </div>
                    </Grid>

                    <Grid item sx={{height: "170px", backgroundColor: "", overflowY: "auto"}}>
                        <Grid container direction={"column"} justifyContent={"center"}
                              justifyItems={"center"} alignItems={"center"}>
                            {words.slice(0, 3).map((w, index) => {
                                return (<Grid item xs={12}>
                                        <KeywordChip variant={"p"} item={w} overFlow={false}
                                                     maxWidth={"220px"} color={CHIP_COLORS[index]}
                                                     textColor={"white"}/></Grid>
                                )
                            })
                            }

                        </Grid>
                    </Grid>

                    <CardActions sx={{position: "absolute", bottom: "0"}}>
                        <Button size="small" onClick={onShareClick}>condividi</Button>
                        <Popover
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <Typography sx={{p: 2}}>L'indirizzo è stato copiato in memoria.</Typography>
                        </Popover>
                        <Button size="small" onClick={onDetailsClick}>dettagli</Button>
                    </CardActions></Grid>

            </Card></Container></Grid>);
}

