import * as React from 'react';
import {selectAccount} from '../authSlice';
import {useSelector} from 'react-redux';
import ValidatorAdvancedSearch from "../../searchResult/validator/ValidatorAdvancedSearch";



export default function ControlPanel() {
    const user = useSelector(selectAccount);
    if (user) {
        return <ValidatorAdvancedSearch/>
    } else return <p className="text-center items-center">Loading ...</p>
}