import { NETWORK } from "../../networkEngine/NETWORK";
import {
    setApiConnectionStatus,
    setApiConnectionStatusDetails
} from "../apiConnectionIndicator/apiConnectionIndicatorSlice";
import { ApiConnectionStatus } from "./const";
import {
    getSuggestedKeywords, setFilteredWords
} from "./DescriptionDataSection/suggestedKeywordsSlice";
import { setAllActivities } from "../registrationForm/businessDataSection/businessDataSectionSlice";
import { API } from "../../networkEngine/api";
import { setHideErrorCamp } from "../errorStore/errorStoreSlice";
import { ACTION_CURRENT_SECTION, ACTION_GET_ACTIVITY_DATA, FETCH_SUGGESTED_KEYWORD } from "./ActionType";
import { Strings } from "../../assets/Strings";

const registerDataMiddleware = (store) => (next) => async (action) => {

    const dispatch = store.dispatch
    // retrieve the available activities and set pagination data
    if (action.type === ACTION_GET_ACTIVITY_DATA) {
        dispatch(setApiConnectionStatus(ApiConnectionStatus.LOADING))
        NETWORK
            .getAllActivitiesWithoutPagination()
            .then((response) => {
                dispatch(setAllActivities(response.data))
                dispatch(setApiConnectionStatus(ApiConnectionStatus.SUCCEED))
                dispatch(setApiConnectionStatus(ApiConnectionStatus.IDLE))
            }).catch((e) => {
                dispatch(setApiConnectionStatusDetails(Strings.it.connectionProblem))
                dispatch(setApiConnectionStatus(ApiConnectionStatus.FAILED))
            })
    }

    // update ui based on the current registration section
    if (action.type === ACTION_CURRENT_SECTION) {
        if (action.payload === 0) {
            dispatch(setHideErrorCamp(true))
        }
    }

    // get available keywords for business description section
    if (action.type === FETCH_SUGGESTED_KEYWORD && action.payload === true) {
        dispatch(setApiConnectionStatus(ApiConnectionStatus.LOADING));
        NETWORK
            .getAllKeyWords(API.keywords)
            .then((response) => {
                dispatch(setApiConnectionStatus(ApiConnectionStatus.IDLE));
                if (response.data) {
                    dispatch(getSuggestedKeywords(response.data))
                    dispatch(setFilteredWords(response.data))
                } else {
                    dispatch(getSuggestedKeywords([]));
                    dispatch(setFilteredWords([]));
                }
            })
            .catch(() => {
                dispatch(setApiConnectionStatusDetails(Strings.it.connectionProblem));
                dispatch(setApiConnectionStatus(ApiConnectionStatus.FAILED));
                dispatch(getSuggestedKeywords([]))
            });
    }
    next(action)
}


export default registerDataMiddleware;

