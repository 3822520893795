import React from 'react';
import TopBar from "../topBar/TopBar";
import Footer from "../footer/Footer";
import {Styles} from '../../design/styles';
import PopupDialog from "../infoDialog/PopupDialog";
import Scaffold from '../navigation/Scaffold'
import {Box} from "@mui/system";
import InformationPanel from "../registrationForm/informationPanel/InformationPanel";
import {Grid} from "@mui/material";

const Layout = () => {
    return _renderUI()
}

export default Layout;

function _renderUI() {
    return (
        <Box style={Styles.Layout.Root}>
            <div style={Styles.General.ErrorSection}><InformationPanel/></div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}> <TopBar/></Grid>
                <Grid item xs={12} md={12}> <Scaffold/></Grid>
                <Grid item xs={12} md={12}> <Footer/></Grid>
            </Grid>
            <PopupDialog/>
        </Box>
    );
}


    