import { createSlice } from "@reduxjs/toolkit";
import { getHealthcareWorkerEmptyObject, getPersonalDataEmptyObject } from "../registrationForm/functions";

const healthcareProviderProfileSlice = createSlice({
    name: 'healthcareProviderProfile',
    initialState: {
        inputPersonalInfo: getPersonalDataEmptyObject(),
        healthcareWorkerData: getHealthcareWorkerEmptyObject(),
        inputBusinessActivity: [],
        inputDescription: "",
        inputSchedule: {},
        selectedInfo: "",
        remoteAvailableActivities: [],
        remoteAvailableKeywords: [],
    },
    reducers: {
        setProfilePersonalInformation: (state, action) => {
            state.inputPersonalInfo = action.payload
        },
        setProfileBusinessActivity: (state, action) => {
            state.inputBusinessActivity = action.payload
        },
        addProfileBusinessActivity: (state, action) => {
            let activity = { name: action.payload.name, parentName: action.payload.parentName }
            state.inputBusinessActivity = [activity, ...state.inputBusinessActivity]
        },
        removeProfileBusinessActivity: (state, action) => {
            let activities = [...state.inputBusinessActivity]
            activities = activities.filter(item => item.name !== action.payload.name)
            state.inputBusinessActivity = activities
        },
        setProfileDescription: (state, action) => {
            state.inputDescription = action.payload
        },
        setProfileSchedule: (state, action) => {
            state.inputSchedule = action.payload
        },
        setProfileSelectedInfo: (state, action) => {
            state.selectedInfo = action.payload
        },
        setProfileAvailableActivities: (state, action) => {
            state.remoteAvailableActivities = action.payload
        },
        setProfileAvailableKeywords: (state, action) => {
            state.remoteAvailableKeywords = action.payload
        },
        setProfileHealthcareWorkerData(state, action) {
            state.healthcareWorkerData = action.payload
        },
    }
})

export default healthcareProviderProfileSlice.reducer

export const selectInputPersonalInfo = (state) => state.healthcareProviderProfile.inputPersonalInfo;
export const selectInputBusinessActivity = (state) => state.healthcareProviderProfile.inputBusinessActivity;
export const selectInputDescription = (state) => state.healthcareProviderProfile.inputDescription;
export const selectInputSchedule = (state) => state.healthcareProviderProfile.inputSchedule;
export const selectSelectedInfo = (state) => state.healthcareProviderProfile.selectedInfo;
export const selectRemoteAvailableActivities = (state) => state.healthcareProviderProfile.remoteAvailableActivities;
export const selectRemoteAvailableKeywords = (state) => state.healthcareProviderProfile.remoteAvailableKeywords;
export const selectHealthcareWorkerData = (state) => state.healthcareProviderProfile.healthcareWorkerData;

export const {
    setPersonalInformation,
    setBusinessActivity,
    addBusinessActivity,
    removeBusinessActivity,
    setDescription,
    setSchedule,
    setSelectedInfo,
    setAvailableActivities,
    setAvailableKeywords,
    setHealthcareWorkerData
} = healthcareProviderProfileSlice.actions