import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useDispatch, useSelector } from 'react-redux';
import { Strings } from '../../../assets/Strings';
import { Styles } from '../../../design/styles';
import { privacyClose, privacyToggleActive, selectPrivacyDialog } from './privacyDialogSlice';
import { DialogTitle, Typography } from '@mui/material';
import { setDoReadInitialInformation } from '../registrationSlice';

const PrivacyPolicyPopup = () => {
    const [scroll] = React.useState('paper');
    const dispatch = useDispatch()
    const shouldBeOpen = useSelector(selectPrivacyDialog)
    const handleClose = () => {
        dispatch(privacyToggleActive());
    };

    const handleRegisterActivity = () => {
        dispatch(privacyClose());
        dispatch(setDoReadInitialInformation(true))
    }
    const descriptionElementRef = React.useRef(null);

    return (
        <div>
            <Dialog
                open={shouldBeOpen}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                {<DialogTitle id="scroll-dialog-title">Informativa resa ai sensi degli articoli 13-14 del GDPR 2016/679 (General Data Protection
                    Regulation</DialogTitle>
                }
                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <Typography variant='h6'>Gentile Signore/a,</Typography>
                        <div style={{margin:4,textAlign:"justify"}} >
                        <Typography variant='p'>{Strings.it.PrivacyPolicyPopup0}</Typography>
                        <Typography variant='h5'>Titolare del trattamento</Typography>
                        <div>
                            Titolare del trattamento è la Società della Salute Alta Val di Cecina Valdera, Via Fantozzi n. 14 56025
                            Pontedera (Pi) nella persona del suo legale rappresentante pro tempore <br></br> Email: 
                                <b> staffsdsvaldera@uslnordovest.toscana.it</b>, <br></br> PEC: <b> direzione.uslnordovest@postacert.toscana.it</b>,
                                <br></br> centralino <b> +39 0587 273111</b>.
                        </div>
                        <Typography variant='h5'>Base giuridica e Finalità del trattamento</Typography>
                        <Typography variant='p'>{Strings.it.PrivacyPolicyPopup2}</Typography>
                        <Typography variant='h5'>Modalità di trattamento e conservazione</Typography>
                        <Typography variant='p'>{Strings.it.PrivacyPolicyPopup3}</Typography>
                        <Typography variant='h5'>Diritti degli interessati</Typography>
                        <div>Gli interessati hanno il diritto di ottenere dalla Società della Salute Alta Val di Cecina Valdera, nei casi
                            previsti, l’accesso ai dati personali e la rettifica o la cancellazione degli stessi, la limitazione del
                            trattamento che li riguarda o di opporsi al trattamento scrivendo all&#39;indirizzo
                            privacy.sdsavcvde@uslnordovest.toscana.it oppure utilizzando l&#39;apposito  Modello per l’esercizio
                            dei diritti in materia di protezione dei dati personali scaricabile dalla sezione Privacy del sito web
                            della Società della Salute Alta Val di Cecina Valdera, da inviare via pec all’indirizzo
                            <b> direzione.uslnordovest@postacert.toscana.it</b>.
                            In qualsiasi momento, è possibile esercitare i seguenti diritti:
                            <div style={{ padding: 8 }}>
                                <li>richiedere maggiori informazioni in relazione ai contenuti della presente informativa</li>
                                <li>accedere ai dati personali;</li>
                                <li> ottenere la rettifica o la cancellazione degli stessi o la limitazione del trattamento che lo
                                    riguardano (nei casi previsti dalla normativa);</li>
                                <li>opporsi al trattamento (nei casi previsti dalla normativa);</li>
                                <li> portabilità dei dati (nei casi previsti dalla normativa);</li>
                                <li> revocare il consenso, ove previsto; la revoca del consenso non pregiudica la liceità del
                                    trattamento basata sul consenso conferito prima della revoca;</li>
                                <li> proporre reclamo all’autorità di controllo (Garante Privacy);</li>
                                <li> dare mandato a un organismo, un’organizzazione o un’associazione senza scopo di lucro per
                                    l’esercizio dei suoi diritti;</li>
                                <li> richiedere il risarcimento dei danni conseguenti alla violazione della normativa.</li> </div></div>
                        <Typography variant='h5'>Periodo di conservazione dei dati</Typography>
                        <Typography variant='p'>{Strings.it.PrivacyPolicyPopup5}</Typography>
                        <Typography variant='h5'>Ambito di comunicazione e diffusione</Typography>
                        <div>
                            La informiamo, inoltre, che i dati raccolti non saranno mai diffusi e non saranno oggetto di
                            comunicazione senza il Suo esplicito consenso, salvo le comunicazioni necessarie che possono
                            comportare il trasferimento di dati personali <p style={{ color: "black", display: "inline" }} > <b>(Nome Referente, Numero di Telefono, Email)</b></p> ai componenti della rete di governance del progetto Cantieri della
                            Salute al fine di agevolare la funzionalità e la piena operatività del progetto stesso e a soggetti terzi,
                            incaricati dalla Società della Salute Alta Val di Cecina Valdera dell’esecuzione di attività direttamente
                            connesse o strumentali alle attività specifiche del progetto Cantieri della Salute e del Comitato di
                            Partecipazione Inoltre tali dati potranno essere conosciuti, nei limiti della normativa vigente da
                            dipendenti e/o collaboratori espressamente nominati ed incaricati dal Titolare.
                        </div>
                        <Typography variant='h5'>Obbligatorietà a del conferimento</Typography>
                        <Typography variant='p'>{Strings.it.PrivacyPolicyPopup7}</Typography>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button

                        onClick={handleRegisterActivity}
                        sx={Styles.PopupDialog.RegisterButton}
                    >
                        Confermo
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default PrivacyPolicyPopup
